import Immutable from 'immutable';
const { fromJS } = Immutable;

export default function documentReducer(state, action) {
  switch (action.type) {
    case 'update_document':
      return updateDocument(state, action);
    case 'upload_file_start':
      return onUploadFileStart(state, action);
    case 'upload_file_end':
      return onUploadFileEnd(state, action);
    case 'prepare_download_doc_end':
      return onDownloadDocEnd(state, action);
    default:
      return state;
  }
}

function updateDocument(state, action) {
  let newState = state;
  const properties = fromJS(action.payload || {});
  newState = newState.mergeIn(['document'], fromJS(properties));
  return newState;
}

function onUploadFileStart(state, action) {
  const { filename } = action.payload;
  const payload = { filename };
  return updateDocument(state, { payload });
}

function onUploadFileEnd(state, action) {
  const { text, dataURL } = action.payload;
  const payload = {
    originalText: text,
    dataURL,
  };
  return updateDocument(state, { payload });
}

function onDownloadDocEnd(state, action) {
  const { filenameOfPatchedDoc, patchedDoc } = action.payload;
  let newState = state;
  newState = newState.setIn(
    ['document', 'filenameOfPatchedDoc'], filenameOfPatchedDoc);
  newState = newState.setIn(
    ['document', 'patched'], patchedDoc);
  return newState;
}
