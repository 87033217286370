import { LitElement, html, css } from "lit-element";
import chevronRight from '~/res/0829-chevron-right.svg';
import { sharedStyles } from "~/elements/shared-styles/shared-styles.js";

class BreadcrumbsView extends LitElement {

  static get properties() {
    return {
      selected: { type: String },
      breadcrumbs: { type: Array },
    };
  }

  static get styles() {
    return [
      sharedStyles,
      css`
        :host { display: block; }
        :host([hidden]) { display: none; }

        #container,
        .chevron {
          color: gray;
        }

        .breadcrumb {
          font-family: var(--font-family-secondary);
          font-size: var(--font-size-sm);
          cursor: default;
        }

        .active {
          color: var(--text-color);
          font-size: var(--font-size-lg);
          font-weight: 700;
        }

        .chevron-container {
          padding-left: var(--padding-sm);
          padding-right: var(--padding-sm);
        }
      `,
    ];
  }

  constructor() {
    super(...arguments);
    this.breadcrumbs = [];
    this.selected = null;
  }

  _onClick(breadcrumb) {
    this.dispatchEvent(new CustomEvent('breadcrumb-click', {
      bubbles: true,
      composed: true,
      detail: { identifier: breadcrumb.identifier },
    }));
  }

  render() {
    const { breadcrumbs, selected } = this;
    return html`
      <div id="container">
        ${this.breadcrumbs.map((b, idx) => html`
          <span class=" breadcrumb ${b.identifier === selected ? 'active' : ''}" @click=${e => this._onClick(b)}>
            ${idx + 1}. ${b.label} <span class="chevron-container" ?hidden=${idx === breadcrumbs.length - 1}> <img class="chevron" src=${chevronRight} alt="" /> </span>
          </span>
        `)}
      </div>
    `;
  }
}
customElements.define('breadcrumbs-view', BreadcrumbsView);