// Other state behavior
import PersistStateBehavior from './persist-state.js';

// Reducers
import stateReducer from '~/reducers/state.js';
import suggestionsReducer from '~/reducers/suggestions.js';
import documentReducer from '~/reducers/document.js';
import ephemeralReducer from '~/reducers/ephemeral.js';
import metricsReducer from '~/reducers/metrics.js';
import pageReducer from '~/reducers/page.js';
import userReducer from '~/reducers/user.js';

const reducers = [
  documentReducer,
  suggestionsReducer,
  metricsReducer,
  ephemeralReducer,
  pageReducer,
  stateReducer,
  userReducer
];

/**
 * StateManagerBehavior
 */
export default superclass => class extends PersistStateBehavior(superclass) {

  get state() {
    return this.__state;
  }

  set state(value) {
    const oldValue = this.__state;
    this.__state = value;

    if ('development' === process.env.NODE_ENV) {
      console.log('App State:');
      console.dir(value && value.toJS ? value.toJS() : value);
    }

    // Ask LitElement to re-render.
    this.requestUpdate('state', oldValue);
  }

  dispatch(action) {
    // Let each reducer update the state based on the action and the output
    // from the previous reducer.
    this.state = reducers.reduce((state, reducer) =>
      reducer(state, action), this.state);
  }
}
