/**
 * UIBehavior
 */
export default superclass => class extends superclass {

  constructor() {
    super(...arguments);
    const report = this;
    this.ui = {
      /**
       * Invoke this method to notify the user that some feature has not been
       * implemented yet.
       */
      notifyNotImplemented: () => {
        this.ui.notify('Coming soon', { duration: 3000 });
      },

      /**
       * Show a `text` in a toast.
       * 
       * @param {string} text 
       * @param {Object} options Available options are:
       *  duration: The amount of time to show the toast. If set to 0, then the
       *  toast persists in the screen.
       */
      notify: (text, options) => {
        const opts = Object.assign({
          duration: 5000, // ms
        }, options);
        this.ui.notification.text = text;
        this.ui.notification.duration = opts.duration;
        this.ui.notification.open();
      },

      /**
       * The `<paper-toast>` used to show notifications.
       */
      get notification() {
        return report.shadowRoot.querySelector('#notification');
      },
    };
  }

}
