import { html, css } from "lit-element";
import '~/elements/suggestion-tag/suggestion-tag.js';
import citeExampleImage from "~/res/cite-example.png";


/**
 * GuideBehavior.
 */
export default superclass => class extends superclass {

  constructor() {
    super(...arguments);
    this.ui.showGuide = () => {
      this.ui.showModal(this.guideHTML);
    };
  }

  get guideHTML() {
    return html`
      <div id="tutorial">
        <div class="section" style="margin-top: 32px">
          <div style="display: flex; flex-flow: row nowrap; justify-content: center; align-items: center; padding-bottom: 32px;">
            <img style="max-width: 115px;" alt="" src="https://writefull.com/img/writefull_logo.svg" />
          </div>
          <h2>Introduction to Writefull Cite</h2>
          <p>
            Good referencing is key to scientific writing. A paper that does not cite other authors’ sources well is unlikely to be trusted by the academic community, and unlikely to get published. Writefull Cite helps the student, researcher or copy-editor make sure that citations have been added to the text where they should. It screens the text for citation completeness, highlighting sentences that it thinks should be backed up by a reference.
          </p>
          <p>
          Writefull Cite is a very powerful tool that not only saves time and increases confidence in referencing completeness for the student, researcher or copy-editor, but also reduces the risk of a proposal or paper being rejected by editors due to incomplete referencing. It also reduces the chances of (unknowingly) plagiarising.
          </p>
        </div>
        <div class="section">
          <h2>Using Writefull Cite</h2>
          <p>
            You can upload any document to Writefull Cite to see which sentences within it are missing citations, as long as it is in .docx, .doc or .tex format. 
          </p>
          <h3>Cite at first sight</h3>
          <p>
            After uploading your document to Writefull Cite, you can see your text with missing citation suggestions from Writefull. At the top of the page, you can see the number of missing citations that Writefull has detected throughout the document. For example, a count of 12 missing citations means that 12 sentences were identified by Writefull as likely to need a citation.
          </p>
          <p>
            If you scroll down your document, you can quickly go past any paragraphs and sentences that are greyed out (as those sentences won’t need your attention).<br>TIP: If you turn on the compact view at the very top of the page, you will see only those paragraphs where citations are missing, so you can go through all the suggestions in your document more quickly.
          </p>
          <h3>Interpreting 'missing citation' suggestions</h3>
          <p>
            The sentences that do need your attention are pointed to by a yellow arrow at the end, as shown in the example below:
          </p>
          <p>
            <img src=${citeExampleImage} alt="Cite Example" />
          </p>
          <p>
            The decimal numbers given in the yellow arrows show the confidence levels of the models. Put simply, what they show you is how confident Writefull is that the sentence should be backed up by a reference. In the example above, Writefull is 80% confident that a reference should be included at the end of the sentence 'In general, it is believed that probiotics help keep up the balance between harmful and beneficial bacteria in the gut thus maintaining a healthy digestive system'. 
          </p>
          <p>
            The numbers are ranging from 0.50 to 1.00 - the closer to 1, the more confident Writefull is. This is very useful information as this tells you where you should seriously consider adding a citation, and where it might perhaps be less critical for you to do so.<br>TIP: You can play with the confidence threshold at the very top of the page (next to Compact View). This allows you to adjust the ‘missing citation’ suggestions shown in your text, depending on how sensitive you want the confidence models to be (or how sure you want to be that you are not missing any citation). 
          </p>
          <h3>How your document is shown in Writefull Cite</h3>
          <p>
            The following parts of your document may be omitted from your text in Writefull Cite:
          </p>
          <p>
            <ul>
              <li>tables, figures and text boxes</li>
              <li>footnotes and headers</li>
              <li>your keywords section</li>
              <li>your reference list</li>
              <li>section numbers and bullet points</li>
              <li>superscript (like <sup>1</sup>)</li>
              <li>symbols and mathematical formulas</li>
            </ul>
          </p>
          <p>
            You might see placeholders instead, like [-table-] and [-math-]. Don’t worry - this is so you can focus on the relevant parts in your document: those sentences that may need to be backed up by a reference. 
          </p>
        </div>
        <div class="section">
          <h2>Any questions?</h2>
          <p>
            Please contact us at <a target="_blank" href="mailto:support@writefull.com">support@writefull.com</a>
          </p>
        </div>
      </div>
      <mwc-button
        slot="primaryAction"
        dialogAction="discard">
        Close
      </mwc-button>
    `;
  }
}
