import Immutable from 'immutable';
const { fromJS } = Immutable;

export default function pageReducer(state, action) {
  switch (action.type) {
    case 'go_to_page':
      return onGoToPage(state, action);
    case 'continue':
      return onContinue(state, action);
    case 'upload_file_start':
      return onUploadFileStart(state, action);
    case 'upload_file_end':
      return onUploadFileEnd(state, action);
    default:
      return state;
  }
}

function onUploadFileStart(state, action) {
  return state;
}

function onUploadFileEnd(state, action) {
  const { error } = action.payload;
  let newState = state;
  newState = state.set('page', error ? 'upload' : 'report');
  return newState;
}

function onContinue(state, action) {
  const page = state.get('page');
  let newPage = page;
  switch (page) {
    case 'report':
      newPage = state.getIn(['document', 'canBePatched'])
        ? 'download'
        : 'continue-to-publisher';
      break
    case 'download':
      newPage = 'continue-to-publisher';
      break
  }
  return state.set('page', newPage);
}

function onGoToPage(state, action) {
  const { page } = action.payload;
  try {
    mixpanel.track(`go_to_${page}`);
  } catch (error) { }
  return state.set('page', page);
}