import { LitElement, html, css } from 'lit-element';
import '~/elements/gauge-view/gauge-view.js';


class ReportHeader extends LitElement {

  static get properties() {
    return {
      title: { type: String, reflect: true },
      metrics: { type: Array },
    };
  }

  constructor() {
    super(...arguments);
    this.metrics = [];
  }

  static get styles() {
    return css`
      :host { display: block; }
      :host([hidden]) { display: none; }

      #title {
        padding: var(--padding-xl);
        padding-bottom: 0;
        font-weight: var(--font-weight-light);
        font-size: var(--font-size-xxl);
        line-height: 1.5;
        text-align: center;
      }

      #metrics {
        display: flex;
        flex-flow: row nowrap;
        justify-content: space-between;
        padding-top: var(--padding-xl);
        padding-left: var(--padding-xxl);
        padding-right: var(--padding-xxl);
        padding-bottom: var(--padding-xl);
      }

      .metric {
        display: flex;
        flex-flow: column nowrap;
        justify-content: center;
        align-items: center;
      }

      .metric-value,
      .metric-title {
        font-family: Roboto;
        font-weight: 300;
      }

      .metric-value {
        font-size: 50px;
      }

      .metric-title {
        text-transform: uppercase;
      }
    `;
  }

  render() {

    // For the citations report we only want to show the citations metric.
    const metrics = this.metrics.filter(m => m.name.toLowerCase() === 'citations');

    return html`
      <div id="container">
        <div id="title" style="display: none;">${this.title}</div>
        <div id="metrics">
          ${metrics.map(m => html`
            <div class="metric">
              <div class="metric-value">${m.value}</div>
              <div class="metric-title" style="text-align: center;">Missing<br>Citations</div>
            </div>
          `)}
        </div>
      </div>
    `;
  }
}
customElements.define('report-header', ReportHeader);
