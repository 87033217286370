import { LitElement, html, css } from "lit-element";
import { sharedStyles } from "~/elements/shared-styles/shared-styles.js";

class FiletypeWarning extends LitElement {
  static get styles() {
    return [
      sharedStyles,
      css`
        :host { display: block }

        #container {
          padding: 32px 130px;
          background: rgba(0,0,0,0.05);
          font-family: var(--font-family-secondary);
        }

        #container h3 {
          margin-top: 0;
        }

        #container p:last-of-type {
          margin-bottom: 0;
        }

        #container ul li:not(:first-of-type) {
          margin-top: 8px;
        }
      `,
    ]
  }
  render() {
    return html`
      <div id="container">
        <h3>⚠️ You have uploaded a .doc document (not a .docx)</h3>
        <p>
          You can still use the service but it will not be possible to accept Writefull's suggestions (in green below) by simply clicking on them.
        </p>
        <p>
          <ul>
            <li>Please return to the <a href="${window.location.href}">starting screen</a> and upload a .docx version to be able to easily accept suggestions.</li>
            <li>If you do not have a .docx version of your manuscript (or are unable to convert to .docx) you are still able to update these manually.</li>
          </ul>
        </p>
      </div>
    `;
  }
}

customElements.define('filetype-warning', FiletypeWarning);
