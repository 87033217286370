
import { html, css } from "lit-element";


/**
 * TermsBehavior
 * 
 * Handles behavior related to the terms of service / privacy.
 */
export default superclass => class extends superclass {

  constructor() {
    super(...arguments);
    this.ui.showTerms = () => {
      this.ui.showModal(this.termsHTML);
    };
  }

  get termsHTML() {
    return html`
      <h2>Terms of Service</h2>
      <p>TODO</p>
    `;
  }
}