import { LitElement, html, css } from "lit-element";
import { sharedStyles } from "~/elements/shared-styles/shared-styles.js";
import { infoPageStyles } from "~/elements/shared-styles/info-page-styles.js";
import "~/elements/continue-button/continue-button.js";
import "~/elements/nav-buttons/nav-buttons.js";

class ContinueToPublisher extends LitElement {

  static get properties() {
    return {
      publisherName: {
        type: String,
        reflect: true,
        attribute: 'publisher-name',
      },
    };
  }

  static get styles() {
    return [
      sharedStyles,
      infoPageStyles,
      css`
        :host { display: block }
        :host([hidden]) { display: none }
      `,
    ];
  }

  _onContinueToPublisher(e) {
    try {
      mixpanel.track('continue-to-publisher');
    } catch (error) { }
    if (window.dev) {
      e.preventDefault();
      e.stopPropagation();
      return alert(`Would now redirect to ${this.shadowRoot.querySelector('#continue-to-publisher-link').href}`);
    }
  }

  render() {

    // FIXME Do not implement this from within the view.
    const report = document.querySelector('writefull-report');
    const sessionUUID = report.state.get('sessionUUID');
    const source = 'writefull';
    const campaign = 'Returning_Writefull';
    const content = sessionUUID;
    let callbackURL = report.state.get('callbackURL') || `https://www.hindawi.com/journals/bmri/?utm_source=${source}&utm_medium=referral&utm_campaign=${campaign}&utm_content=${content}`;
    const finalRedirectURL = `${callbackURL}&utm_content=${content}&writefull_s=${content}`;

    const leftNavLabel = report.state.getIn(['document', 'canBePatched'])
      ? 'Go to the download page'
      : 'Go to the report';

    return html`
      <nav-buttons .left=${{ label: leftNavLabel }}></nav-buttons>
      <div id="container">
        <div id="main">
          <h3>Thank you for using Writefull!</h3>
          Return to Hindawi's submission page to submit your revised manuscript.
          <div id="next-step-container">
            <a id="continue-to-publisher-link" @click=${e => this._onContinueToPublisher(e)} target="_blank" alt="" href="${finalRedirectURL}">Continue to Hindawi</a>
            <!-- <continue-button
              label="Continue to ${this.publisherName}"></continue-button> -->
          </div>
        </div>
      </div>
    `;
  }

}
customElements.define('continue-to-publisher', ContinueToPublisher);