import { LitElement, html, css } from "lit-element";
import "@material/mwc-button";
import "@material/mwc-textfield";
import { sharedStyles } from "~/elements/shared-styles/shared-styles.js";
import { infoPageStyles } from "~/elements/shared-styles/info-page-styles";

class SigninView extends LitElement {
  static get properties() {
    return {
      email: { type: String },
      password: { type: String },
      disabled: { type: Boolean },
      strings: { type: Object },
    };
  }

  constructor() {
    super(...arguments);
    this.email = '';
    this.password = '';
    this.disabled = false;
    this.strings = {};
  }

  get emailInput() {
    return this.shadowRoot.querySelector('#email');
  }

  get passwordInput() {
    return this.shadowRoot.querySelector('#password');
  }

  get signInButton() {
    return this.shadowRoot.querySelector('#signIn');
  }

  _onSignInClick(e) {


    const email = this.emailInput.value;
    const password = this.passwordInput.value;

    e.preventDefault();
    e.stopPropagation();

    this.dispatchEvent(new CustomEvent('login', {
      bubbles: true,
      composed: true,
      detail: {
        email,
        password,
        emailValid: this.emailInput.checkValidity(),
      },
    }));

  }


  static get styles() {
    return [
      sharedStyles,
      infoPageStyles,
      css`
        :host {
          display: block;
        }
        :host([hidden]) { display: none; }

        #main {
          width: 100%;
          display: flex;
          flex-flow: column nowrap;
          align-content: center;
        }

        form {
          display: flex;
          flex-flow: column nowrap;
          margin-top: var(--padding-xl);
        }

        form mwc-textfield {
          max-width: 400px;
          font-size: var(--font-size-sm);
          margin-bottom: var(--padding);
        }

        form mwc-button {
          display: block;
        }
      `
    ];
  }

  render() {

    // FIXME: 19 February 2022
    // The validation of mwc-textfield is broken in version ^0.14.1.
    // Instead, we simply check that the required inputs have a non empty
    // value and delegate proper credential validation to a higher
    // level component.
    const canSubmit = !!(
      this.emailInput &&
      this.emailInput.value.length &&
      this.passwordInput &&
      this.passwordInput.value.length
    );

    return html`
      <div id="container">
        <div id="main">
          <h3 class="header">Sign in to Writefull Cite</h3>
          <span id="main-copy">
            This is an institutional instance of Writefull Cite. Please enter your Writefull credentials to continue. If you do not have an account with Writefull, you may <a target="_blank" href="https://my.writefull.com/signup">create one here</a> using your institutional email address.
          </span>

          <form>
            <mwc-textfield
              id="email"
              label="Email"
              type="email"
              value=${this.email || ''}
              .autoValidate=${false}
              @input=${(e) => { this.email = this.emailInput.value }}
              outlined
            ></mwc-textfield>

            <mwc-textfield
              id="password"
              label="Password"
              type="password"
              value=${this.password || ''}
              .autoValidate=${false}
              @input=${(e) => { this.password = this.passwordInput.value }}
              @keyup=${(e) => { e.code === 'Enter' && this.signInButton.click() }}
              outlined
            ></mwc-textfield>

            <mwc-button
              id="signIn"
              label="Sign In"
              ?disabled=${this.disabled || !canSubmit}
              @click=${e => this._onSignInClick(e)}
              outlined
            ></mwc-button>            
          </form>
        </div>
      </div>
    `;
  }
}
customElements.define("signin-view", SigninView);

