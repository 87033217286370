import { LitElement, html, css } from "lit-element";
import '@material/mwc-button';
import '@material/mwc-dialog';
import { sharedStyles } from '~/elements/shared-styles/shared-styles.js';


class ModalView extends LitElement {

  static get properties() {
    return {
      open: { type: Boolean },
    };
  }

  static get styles() {
    return [
      sharedStyles,
      css`
        :host {
          --modal-max-width: 700px;
          --modal-min-width: 700px;
        }
        :host { display: block }
        :host[hidden] { display: none }

        mwc-dialog {
          --mdc-dialog-min-width: var(--modal-min-width);
          --mdc-dialog-max-width: var(--modal-max-width);
        }
      `,
    ];
  }

  constructor() {
    super(...arguments);
    this.open = false;
  }

  show() {
    this.open = true;
  }

  hide() {
    this.open = false;
  }

  toggle() {
    this.open = !this.open;
  }

  _onClosing(e) {
    this.dispatchEvent(new CustomEvent('closing', {
      bubbles: false,
      composed: false,
      detail: {},
    }));
  }

  _onClosed(e) {
    this.dispatchEvent(new CustomEvent('closed', {
      bubbles: false,
      composed: false,
      detail: {},
    }));
  }

  render() {
    return html`
      <mwc-dialog id="dialog"
        @closing=${e => this._onClosing(e)}
        @closed=${e => this._onClosed(e)}
        ?open=${this.open}>

        <!-- A slot to hold the main content of the modal. -->
        <slot></slot>

        <!-- We create a slot 'primaryAction' and forward it to the slot
        with the same name in <mwc-dialog> -->
        <slot name="primaryAction" slot="primaryAction"></slot>

        <!-- The same as with 'primaryAction' -->
        <slot name="secondaryAction" slot="secondaryAction"></slot>
      </mwc-dialog>
    `;
  }

}
customElements.define('modal-view', ModalView);