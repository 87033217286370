import { LitElement, html, css } from "lit-element";
import "@material/mwc-button";

class ContinueButton extends LitElement {

  static get properties() {
    return {
      label: { type: String, reflect: true },
      disabled: { type: Boolean, reflect: true },
    };
  }

  constructor() {
    super(...arguments);
    this.disabled = false;
  }

  _onClick(e) {
    this.dispatchEvent(new CustomEvent('continue', {
      bubbles: true,
      composed: true,
      detail: {},
    }));
  }

  render() {
    return html`
      <mwc-button
        ?disabled=${this.disabled}
        outlined
        label=${this.label || 'Continue'}
        @click=${e => this._onClick(e)}
      ></mwc-button>
    `;
  }

}
customElements.define('continue-button', ContinueButton);