import { LitElement, html, css } from "lit-element";
import '@material/mwc-button';
import { sharedStyles } from '~/elements/shared-styles/shared-styles.js';
import chevronLeft from '~/res/0828-chevron-left.svg';


class NavButtons extends LitElement {

  static get properties() {
    return {
      left: { type: Object },
      right: { type: Object },
    };
  }

  static get styles() {
    return [
      sharedStyles,
      css`
        :host {
          display: block;
          font-family: var(--font-family-secondary);
        }
        :host[hidden] { display: none }

        #container {
          padding-top: var(--padding-xl);
          padding-left: var(--padding-xl);
        }

        .button {
          color: gray;
        }

        .button:hover {
          color: var(--text-color);
        }
      `,
    ];
  }

  constructor() {
    super(...arguments);
    this.left = {};
    this.right = {};
  }

  _onLeftClick(e) {
    this.dispatchEvent(new CustomEvent('navbar-left-click', {
      bubbles: true,
      composed: true,
      detail: {},
    }));
  }

  render() {
    return html`
      <div id="container">
        <div id="left-container" @click=${e => this._onLeftClick(e)}>
          <div class="button">
            ${this.left.label ? html`
              <img src=${chevronLeft} alt="" class="chevron" /> ${this.left.label}
            ` : ''}
          </div>
        </div>
      </div>
    `;
  }

}
customElements.define('nav-buttons', NavButtons);