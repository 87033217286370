import { LitElement, html, css } from 'lit-element';
import { sharedStyles } from "~/elements/shared-styles/shared-styles.js";
import '~/elements/breadcrumbs-view/breadcrumbs-view.js';
import '~/elements/report-header/report-header.js';
import '~/elements/suggest-section/suggest-section.js';
import '~/elements/footer-view/footer-view.js';
import '~/elements/upload-view/upload-view.js';
import '~/elements/signin-view/signin-view.js';
import '~/elements/download-view/download-view.js';
import '~/elements/continue-button/continue-button.js';
import '~/elements/continue-to-publisher/continue-to-publisher.js';
import '~/elements/filetype-warning/filetype-warning.js';
import '@material/mwc-button';


class ReportView extends LitElement {

  static get properties() {
    return {
      strings: { type: Object },
      page: { type: String },
      title: { type: String, reflect: true },
      originalText: { type: String },
      filenameOfPatchedDoc: { type: String },
      interactive: { type: Boolean, reflect: true },
      showFiletypeWarning: { type: Boolean, reflect: true, attribute: 'show-filetype-warning' },
      parts: { type: Array },
      metrics: { type: Object },
      uploading: { type: Boolean, reflect: true },
      downloading: { type: Boolean, reflect: true },
      canDownload: { type: Boolean, reflect: true, attribute: 'can-download' },
      showUploadButton: { type: Boolean, reflect: true, attribute: 'show-upload-button' },
      suggestions: { type: Array },
      suggestionsThatCouldNotBeApplied: { type: Array },
      loading: { type: Boolean },
      showUploadButton: { type: Boolean, reflect: true, attribute: 'show-upload-button' },

    };
  }

  constructor() {
    super();
    this.metrics = [];
    this.suggestions = [];
    this.showUploadButton = false;
    this.downloading = false;
    this.canDownload = false;
  }

  static get styles() {
    return [
      sharedStyles,
      css`
        :host {
          display: block;
          box-shadow: 0 1px 2px 0 rgba(60,64,67,.3), 0 1px 3px 1px rgba(60,64,67,.15);
          border-radius: 2px;
        }

        :host([hidden]) { display: none; }

        #container {
          background: white;
          display: flex;
          flex-flow: column nowrap;
          justify-content: center;
          align-items: center;
        }

        #container :first-child {
          width: 100%;
        }

        report-header {
          border-bottom: 1px solid var(--separator-color);
        }

        suggest-section {
          padding-right: var(--padding-xl);
        }

        footer-view {
          border-top: 1px solid var(--separator-color);
        }

        #empty {
          display: flex;
          flex-flow: row nowrap;
          justify-content: center;
          align-items: center;
          font-size: var(--font-size-xxl, 24px);
          font-weight: var(--font-weight-light);
        }

        breadcrumbs-view {
          padding-top: var(--padding-xl);
          padding-left: var(--padding-xl);
        }

        #footer-bar {
          position: fixed;
          bottom: 0; left: 0;
          width: 100vw;
          background: white;
          display: flex;
          flex-flow: row nowrap;
          justify-content: center;
          align-items: center;
          padding: var(--padding);
          border-top: 1px solid rgba(0, 0, 0, 0.1);
        }

        #footer-bar .left,
        #footer-bar .right {
          font-family: var(--font-family-secondary);
          font-size: var(--font-size-sm);
          flex: 100;
        }

        #footer-bar .left {
          text-align: right;
          padding-right: var(--padding);
        }

        #footer-bar .right {
          padding-left: var(--padding);
        }
      `
    ];

  }


  render() {
    const {
      page,
      title,
      filenameOfPatchedDoc,
      interactive,
      showFiletypeWarning,
      metrics,
      parts,
      downloading,
      canDownload,
      showUploadButton,
      suggestions,
      suggestionsThatCouldNotBeApplied,
    } = this;
    const hasParts = parts && parts.length > 0;

    const shouldContinueToDownload = false;
    const shouldContinueToSubmit = !interactive;

    const continueToDownloadLabel = 'Continue to Download';

    const acceptedSuggestions = [];

    return html`
      <div id="container">
        
        ${page === 'signin' ? html`<signin-view></signin-view>` : ''}

        ${page === 'upload' ? html`
          <upload-view
            ?show-upload-button=${showUploadButton}
            ?uploading=${this.uploading}></upload-view>
        ` : ''}

        ${page === 'report' ? html`
          ${showFiletypeWarning ? html`
            <filetype-warning></filetype-warning>
          ` : ''}
          <report-header
            .title=${title}
            .metrics=${metrics}></report-header>

            ${this.loading && (!parts || !parts.length) ? html`
              <div style="font-size: 1.5em; padding: 32px; display: flex; flex-flow: row nowrap; justify-content: center; align-items: center; font-family: 'Libre Franklin'">
                Analyzing your document...
              </div>
            ` : ''}

          ${parts.map(({ text, UUID, suggestions, number }) => html`
            <suggest-section
              data-uuid=${UUID}
              section-number=${number}
              .interactive=${interactive}
              .suggestions=${suggestions}
              .text=${text}></suggest-section>
          `)}
          ${shouldContinueToDownload ? html`
            <div id="footer-bar">
              <div class="left">
                <span hidden>${acceptedSuggestions.length} / ${suggestions.length} suggestions accepted</span>
              </div>
              <div class="middle">
                <continue-button label=${continueToDownloadLabel}
                  ?disabled=${!canDownload}
                  @continue=${e => this.dispatchEvent(new CustomEvent('download-request'))}>
                </continue-button>
              </div>
              <div class="right"></div>
            </div>
          ` : ''}
          ${shouldContinueToSubmit ? html`
            <div id="next-step-container" style="padding-bottom: var(--padding-xxl)">
              <continue-button label="Continue"></continue-button>
            </div>
          ` : ''}
        ` : ''}


        ${page === 'download' ? html`
          <download-view
            filename=${filenameOfPatchedDoc || ''}
            .suggestionsThatCouldNotBeApplied=${suggestionsThatCouldNotBeApplied}>
          </download-view>
        ` : ''}

        ${page === 'continue-to-publisher' ? html`
          <continue-to-publisher
            publisher-name="Hindawi"></continue-to-publisher>
        ` : ''}

        <footer-view
          ?download-button-disabled=${!canDownload || (canDownload && downloading)}
          ?downloading=${downloading}
          ></footer-view>

      </div>
    `;
  }
}
customElements.define('report-view', ReportView);
