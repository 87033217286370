import { css } from 'lit-element';

export const infoPageStyles = css`
  a {
    color: var(--text-color);
  }

  #container {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: center;
    padding: 64px 0;
  }

  #main {
    font-size: var(--font-size-lg);
    font-weight: var(--font-weight-light);
    padding: var(--padding-xl);
    padding-bottom: var(--padding-xl);
    max-width: 75%;
    line-height: 1.5em;
  }

  #main-copy {
  }

  #main-demo-link-container {
    margin-top: var(--padding-sm);
  }

  #main-demo-link {
    color: black;
  }

  #main .header {
    font-family: var(--font-family-secondary);
    font-weight: 500;
  }
`;