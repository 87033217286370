/**
 * NavigationBehavior
 */
export default superclass => class extends superclass {

  constructor() {
    super(...arguments);
  }

  _onContinue() {
    if (this.state.get('page') === 'continue-to-publisher') {
      // TODO: Open a new tab with the callback provided by the publisher.
      // This is currently implemented in <continue-to-publisher>.
    }
    this.dispatch({ type: 'continue' });
  }

  _onNavbarLeft() {
    switch (this.state.get('page')) {
      case 'download':
        this.dispatch({ type: 'go_to_page', payload: { page: 'report' } });
        break;
      case 'continue-to-publisher':
        this.dispatch({
          type: 'go_to_page',
          payload: {
            page: this.state.getIn(['document', 'canBePatched'])
              ? 'download'
              : 'report',
          },
        });
        break;
      default:
        break;
    }
  }

}
