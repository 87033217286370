import undoIcon from '~/res/0729-undo2.png';

import { LitElement, html, css } from "lit-element";
import "~/elements/annotation-view/annotation-view.js";

/**
 * SuggestionTag
 *
 * Usage:
 *
 * Removing a word:
 *
 *     <suggestion-tag remove="the"></suggestion-tag>
 *
 * Inserting a word:
 *
 *     <suggestion-tag add="the"></suggestion-tag>
 *
 * Replacing a word, and making the tag interactive:
 *
 *     <suggestion-tag remove="less" add="fewer" interactive></suggestion-tag>
 * 
 * Annotating a word:
 * 
 *     <suggestion-tag
 *         annotation="Check spelling"
 *         style="--background-color: pink;">bactera</suggestion-tag>
 */
class SuggestionTag extends LitElement {
  static get properties() {
    return {
      add: {
        converter: value => {
          try {
            const parsedValue = JSON.parse(value);
            if (typeof parsedValue === 'number') {
              return String(parsedValue);
            }
            if (Array.isArray(parsedValue)) {
              return parsedValue.map(val => String(val));
            }
            return parsedValue;
          }
          catch (error) { return String(value) }
        },
      },
      remove: { type: String },
      accepted: { type: Boolean },
      acceptedValue: { type: String, attribute: "accepted-value" },
      suggestionUUID: { type: String, attribute: "suggestion-uuid" },
      annotation: { type: String },
      interactive: { type: Boolean },
    };
  }

  constructor() {
    super(...arguments);
    this.add = "";
    this.remove = "";
    this.annotation = "";
    this.accepted = false;
    this.acceptedValue = null;
    this.interactive = false;
  }

  _onAcceptReplacement(replacement) {
    // If the tag is not interactive, we should not emit
    // this event.
    if (!this.interactive) return;

    // The replacement will be undefined when the user
    // clicks in a part of the tag that does not have
    // a value, e.g., an `[or]` annotation. In this case
    // we do not want to emit the event.
    if (replacement === undefined) return;

    this.dispatchEvent(new CustomEvent("accept-replacement", {
      bubbles: true,
      composed: true,
      detail: {
        suggestionUUID: this.suggestionUUID,
        replacement,
      },
    }));
  }

  _onUndoAcceptance(acceptedValue) {
    if (!this.interactive) return;
    this.dispatchEvent(new CustomEvent("undo-accept-replacement", {
      bubbles: true,
      composed: true,
      detail: {
        suggestionUUID: this.suggestionUUID,
        replacement: acceptedValue,
      },
    }));
  }

  static get styles() {
    return css`
      :host {
        --tag-radius: 0px;
        --remove-color: #FF7A7A;
        --add-color: #27DEB8;
        display: inline;
      }

      :host([hidden]) {
        display: none;
      }

      :host(:hover:not([disabled])) {
      }

      #container {
        display: inline-flex;
        line-height: calc(var(--line-height, 32px)/2);
        cursor: default;
      }

      :host([disabled]) #container {
        cursor: default;
      }

      .add,
      .remove,
      .annotation {
        padding: var(--padding-xs);
        color: var(--text-color);
      }

      .add {
        background-color: var(--add-color);

        /*
         * The add part always comes last, and as a result its left border
         * should always have a radius.
         */
        border-top-right-radius: var(--tag-radius);
        border-bottom-right-radius: var(--tag-radius);
      }

      .add .tag-text {
        border-bottom: 3px solid transparent;
        padding: var(--padding-xxs);
      }

      .add:not(.lonely-tag) .tag-text:first-child {
        padding-left: 14px;
      }

      #container:not(.disabled) .add .tag-text {
        cursor: pointer;
      }

      #container:not(.accepted):not(.disabled) .add .tag-text:hover {
        /* border-bottom: 2px solid white; */
      }

      .remove {
        background-color: var(--remove-color);

        /*
         * The remove part always comes first, and as a result its left border
         * should always have a radius.
         */
        border-top-left-radius: var(--tag-radius);
        border-bottom-left-radius: var(--tag-radius);

        /* To allow positioning of the arrow. */
        position: relative;


        padding-right: 8px;
        border-top-right-radius: 2px;
        border-bottom-right-radius: 2px;
        background: gold;
      }

      .remove .tag-text {
        /*
         * Uncomment to cross the incorrect word.
         */
        /*text-decoration: line-through;*/
        font-size: 12px;
      }

      .remove.lonely-tag {
        /**
         * The remove tag is only clickable when the suggestion is to remove
         * a word; in contrast, it should not be clickable in the context
         * of it appearing in a replacement suggestion. In this latter case,
         * it would not be a .lonely-tag.
         */
        cursor: default;

        border-top-right-radius: 2px;
        border-bottom-right-radius: 2px;
      }

      #container.accepted:hover {
        background: rgba(0, 0, 0, 0.05);
      }

      #container.accepted .add,
      #container.accepted .remove {
        color: var(--text-color, black);
        background-color: transparent;
        border: 1px solid lightgray;
      }

      #container.accepted .add .tag-text:hover {
        text-decoration: initial;
      }

      .lonely-tag {
        /*
         * When a tag is standing by itself, the border radius should be applied
         * to all borders.
         */
        border-radius: var(--tag-radius);
      }

      .annotation {
        background-color: var(--background-color, lightgray);
        color: var(--text-color);
      }

      .left,
      .right {
        display: inline-flex;
      }

      .undo-icon {
        width: 20px;
        opacity: 0.5;
        vertical-align: text-bottom;
        cursor: pointer;
      }

      .arrow {
        position: absolute;
        top: 0;
        left: -15px;
        width: 0; 
        height: 0; 
        border-top: 12px solid transparent;
        border-bottom: 13px solid transparent;
        border-right: 15px solid gold;
      }
    `;
  }

  render() {
    const {
      add,
      remove,
      accepted,
      acceptedValue,
      annotation,
      interactive,
    } = this;

    const hasWordToRemove = true;
    const hasWordToAdd = false;
    const hasMultipleReplacements = Array.isArray(add);
    const hasAnnotation = annotation && annotation.length;

    const removeFragment = hasWordToRemove ? html`
      <span style="${accepted ? 'display: none' : ''}"
            class="remove ${hasWordToAdd ? '' : 'lonely-tag'}"
            @click=${e => { !hasWordToAdd ? this._onAcceptReplacement('') : null }}>
        <span class="arrow"></span>
        <span class="left" style="${hasWordToAdd ? 'display: none' : ''}"></span>
        <span class="right">
          <span class="tag-text">${remove}</span>
        </span>
      </span>

      ${!hasWordToAdd ? html`
        <span style="${!accepted ? 'display: none' : ''}"
              class="remove lonely-tag"
              @click=${e => accepted && this._onUndoAcceptance(acceptedValue)}>
          <span class="left">
            <span class="tag-text"
              style="text-decoration-line: line-through;">${remove}</span>
          </span>
          <img alt="undo" src="${undoIcon}" ?hidden=${!accepted} class="undo-icon" />
        </span>
      ` : ''}
    ` : '';

    const multipleReplacementsFragment = Array.isArray(add) ?
      add.map(replacement => html`
        <span class="tag-text" data-add-value=${replacement}>
          ${replacement}
        </span>
      `).reduce((a, b) => html`
        ${a} <annotation-view>or</annotation-view> ${b}
      `) : html``;

    const addFragment = hasWordToAdd ? html`
      <span class="add ${hasWordToRemove && !accepted ? '' : 'lonely-tag'}" @click=${e => {
        if (accepted) {
          this._onUndoAcceptance(acceptedValue);
        } else {
          this._onAcceptReplacement(Array.isArray(add) ?
            e.target.dataset.addValue : add);
        }
      }}>
        ${!hasMultipleReplacements ? html`
          <span class="tag-text">${add.trim()}</span>
        ` : ''}
        ${hasMultipleReplacements && !accepted ? html`
          ${multipleReplacementsFragment}
        ` : ''}
        ${hasMultipleReplacements && accepted ? html`
          <span class="tag-text">${acceptedValue}</span>
        ` : ''}
        <img alt="undo" src="${undoIcon}" ?hidden=${!accepted} class="undo-icon" />
      </span>
    ` : '';

    const annotationFragment = hasAnnotation ? html`
      <span class="annotation lonely-tag">
        <annotation-view>${annotation}</annotation-view>
        <slot></slot>
      </span>
    ` : '';

    return html`
      <span id="container"
        class="${accepted === true ? 'accepted' : ''} ${interactive ? '' : 'disabled'}">
        ${removeFragment}${addFragment}${annotationFragment}
      </span>
    `;
  }
}
customElements.define("suggestion-tag", SuggestionTag);
