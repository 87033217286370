import { LitElement, html, css } from 'lit-element';

import '~/elements/suggestion-tag/suggestion-tag.js';
import '~/elements/long-sentence/long-sentence.js';


class SuggestSection extends LitElement {

  static get properties() {
    return {
      interactive: { type: Boolean, reflect: true },
      sectionNumber: {
        type: Number,
        reflect: true,
        attribute: 'section-number',
      },
      text: { type: String, reflect: false },
      suggestions: { type: Object },
    };
  }

  constructor() {
    super();
    this.text = '';
    this.suggestions = null;
    this._placeholderRe = /(\n|^)(\s*\[\s*-[^\]\-]*-\s*\]\s*){1,}(\n|$)/g;
  }

  static get styles() {
    return css`
      :host {
        --text-color-dimmed: rgba(0, 0, 0, 0.2);
        display: block;
      }
      :host([hidden]) { display: none; }

      #container {
        display: flex;
        flex-flow: row nowrap;
      }

      #left {
        border-right: 1px dashed lightgray;
        line-height: var(--line-height);
        padding: var(--padding) var(--padding-sm);
        color: var(--text-color);
      }

      #right {
        display: block;

        flex: 1;
        text-align: left;
        line-height: var(--line-height);
        padding: var(--padding);
        font-size: var(--font-size);
        font-weight: 200;
        color: var(--text-color-dimmed);
      }

      #right.section-title {
        font-size: 1.1em;
        font-weight: bold;
      }

      .number-badge {
        --width: 40px;

        display: flex;
        flex-flow: row nowrap;
        justify-content: center;
        align-items: flex-start;
        font-family: Roboto;
        font-weight: 300;
        color: var(--text-color);
        background: transparent;
        width: var(--width);
        height: var(--width);
        font-size: var(--font-size);
        border-radius: 50%;
      }

      .placeholder {
        color: var(--text-color-dimmed);
      }
    `;
  }

  render() {
    const { sectionNumber, text, suggestions, interactive } = this;

    let modifiedText = text;
    let htmlWithSuggestions = html``;

    const isPlaceholder = !suggestions.length;

    if (suggestions && suggestions.length) {
      /*
       * Map each sentence text to a "modifications context".
       *
       *  {
       *    "We explore...": {
       *      // `modified` will contain the result of applying all the
       *      // suggestions in `suggestions` to the original sentence.
       *      //
       *      modified: "We explore...",
       *
       *      // all the suggestions for the original sentence.
       *      // These should be sorted by `index`.
       *      //
       *      suggestions: [{target: "wether", index: 52, ...}, ...]
       *
       *      // `j` keeps track of the delta in length between the original
       *      // sentence, and the modified sentence.
       *      // Every time a suggestion is applied it may alter the length
       *      // of the modified sentence with respect to the original sentence
       *      // used to compute the suggestions. We need to save this delta
       *      // so that the index for subsequent suggestions can be adjusted.
       *      //
       *      j: 0,
       *    }
       *  }
       */
      const S = {};
      for (const s of suggestions) {
        if (!(s.sentenceText in S)) {
          S[s.sentenceText] = {
            modified: s.sentenceText,
            suggestions: [],
            templates: {},
            j: 0,
          };
        }
        S[s.sentenceText].suggestions.push(s);
      }

      for (const [sentenceText, ctx] of Object.entries(S)) {

        // Sort suggestions by index.
        ctx.suggestions.sort((s0, s1) => s0.index - s1.index);

        // TODO: Commented out for citations.
        //
        // Given that we are not showing confidence levels, we remove from the
        // replacements the string written by the user (because we do not want
        // to offer it as a suggested alternative).
        // for (const s of ctx.suggestions) {
        //   s.replacements = s.replacements.filter(r => r.word !== s.target);
        // }

        // TODO: Remove overlapping suggestions.
      }

      // Apply suggestions.

      for (const [sentenceText, ctx] of Object.entries(S)) {
        for (const s of ctx.suggestions) {
          const {
            target,
            type,
            replacements,
            suggestions,
            suggestionUUID,
            accepted,
            acceptedValue,
          } = s;
          const hasReplacements = suggestions && suggestions.length > 0;

          let htmlTemplateForSuggestion = html``;
          if (type.includes('length')) {
            // htmlTemplateForSuggestion = html`${target}`
          } else if (type.includes('spell') && !hasReplacements) {
            htmlTemplateForSuggestion = html`
              <suggestion-tag disabled annotation="Check spelling">${target}</suggestion-tag> 
            `;
          } else if (type.includes('citation')) {
            const score = replacements.find(r =>
              r.word.includes('WITH CITE')).score;
            const tagText = `MISSING CITATION? (${String(score).slice(0, 5)})`;
            htmlTemplateForSuggestion = html`
              <span class="citation" style="color: var(--text-color)">${target}</span> <suggestion-tag style="margin-left: 15px;" remove=${tagText}></suggestion-tag>
            `;
          } else {
            let add = '';
            if (hasReplacements && Array.isArray(replacements)) {
              if (replacements.length === 1) {
                add = replacements[0].word;
              } else { // Multiple replacements.
                add = replacements.map(r => r.word);
              }
            }
            htmlTemplateForSuggestion = html`
              <suggestion-tag
                ?interactive=${interactive}
                ?accepted=${!!accepted}
                .remove=${target}
                .add=${add}
                .acceptedValue=${acceptedValue}
                .suggestionUUID=${suggestionUUID}
              ></suggestion-tag>
            `
          }
          const insertionIdx = ctx.j + s.index;
          const placeholder = `::::s:${suggestionUUID}::::`;
          ctx.modified = [
            ctx.modified.slice(0, insertionIdx),
            placeholder,
            ctx.modified.slice(insertionIdx + s.target.length),
          ].join('');
          ctx.j += placeholder.length - s.target.length;
          ctx.templates[suggestionUUID] = htmlTemplateForSuggestion;
        }
      }

      // For each sentence for which we have suggestions, replace the sentence
      // with the HTML markup needed to render the suggestions.

      for (const [sentenceText, ctx] of Object.entries(S)) {
        let sentenceIdx = modifiedText.indexOf(sentenceText);
        if (sentenceIdx !== -1) {
          modifiedText = [
            modifiedText.slice(0, sentenceIdx),
            ctx.modified,
            modifiedText.slice(sentenceIdx + sentenceText.length),
          ].join('')
        }
      }

      const templateMap = {};
      Array.from(Object.entries(S)).forEach(([, ctx]) => {
        Object.assign(templateMap, ctx.templates);
      });

      let remaining = modifiedText;
      let placeholderReg = /::::s:(.*?)::::/g
      let match;
      while ((match = placeholderReg.exec(remaining))) {
        const [placeholder, suggestionUUID] = match;
        const suggestionTemplate = templateMap[suggestionUUID];
        htmlWithSuggestions =
          html`${htmlWithSuggestions}${remaining.slice(0, match.index)}${suggestionTemplate}`
        remaining = remaining.slice(match.index + placeholder.length);
        placeholderReg.lastIndex = 0;
      }
      htmlWithSuggestions = html`${htmlWithSuggestions}${remaining}`;
    } else {
      htmlWithSuggestions = html`${text}`;
    }

    return html`
      <div id="container">
        <div id="left">
          <div class="number-badge ${isPlaceholder ? 'placeholder' : ''}">${sectionNumber}</div>
        </div>
        <div id="right" class="${isPlaceholder ? 'placeholder' : ''}">
          ${htmlWithSuggestions}
        </div>
      </div>
    `;
  }
}
customElements.define('suggest-section', SuggestSection);
