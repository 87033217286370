import { LitElement, html, css } from "lit-element";
import "@material/mwc-button";


class FooterView extends LitElement {

  static get properties() {
    return {
      showDownloadButton: { type: Boolean, reflect: true, attribute: 'download-button' },
      downloadButtonDisabled: { type: Boolean, reflect: true, attribute: 'download-button-disabled' },
      downloading: { type: Boolean, reflect: true },
    };
  }

  constructor() {
    super(...arguments);
    this.showDownloadButton = false;
    this.downloadButtonDisabled = true;
    this.downloading = true;
  }

  _onDownload(e) {
    this.dispatchEvent(new CustomEvent('download-request', {
      bubbles: true,
      composed: true,
      detail: {},
    }));
  }

  static get styles() {
    return css`
      :host {
        display: flex;
        flex-flow: column nowrap;
        justify-content: center;
        align-items: center;
        padding: var(--padding-xxl, 24px);
        padding-top: 60px;
        padding-bottom: 80px;
        font-weight: var(--font-weight-light);
        font-size: 15px;
      }

      :host([hidden]) { display: none; }

      #download {
        margin-bottom: var(--padding-lg);
        --mdc-theme-primary: black;
        --mdc-theme-secondary: black;
      }

      #help {
        margin-top: var(--padding);
      }

      #help a {
        color: var(--text-color);
      }

    `;
  }

  render() {
    return html`
      ${this.showDownloadButton ? html`
        <mwc-button id="download"
          outlined
          ?disabled=${this.downloadButtonDisabled}
          @click=${e => this._onDownload(e)}
          label="Download Revised Manuscript"
          ></mwc-button>
      ` : ''}
      <div id="copyright">
        (c) 2014 – 2022 Writefull.<br>
      </div>
      <div id="help">
        <a target="_blank" href="mailto:help@writefull.com">support@writefull.com</a>
      </div>
    `;
  }
}
customElements.define('footer-view', FooterView);
