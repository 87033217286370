import Immutable from 'immutable';
const { List, fromJS } = Immutable;


export default function suggestionsReducer(state, action) {
  switch (action.type) {
    case 'add_suggestions': {
      let newState = state;
      newState = addSuggestions(newState, action);
      newState = computeFilteredCitations(newState, action);
      return newState;
    }
    case 'accept_suggestion':
      return acceptSuggestion(state, action);
    case 'undo_accept_suggestion':
      return undoAcceptSuggestion(state, action);
    case 'toggle_suggestion':
      return toggleSuggestion(state, action);
    case 'prepare_download_doc_end':
      return onDownloadDocEnd(state, action);
    case 'set_citations_threshold':
      return computeFilteredCitations(state, action);
    default:
      return state;
  }
}

function computeFilteredCitations(state, action) {

  let newState = state;

  const threshold = newState.get('citationsThreshold');

  let suggestions = newState.getIn(['suggestions', 'all']).toJS()
  suggestions = suggestions.filter(s => {
    const withCitations = s.replacements.find(r => r.word.includes('WITH CITE'));
    return withCitations.score >= threshold;
  });

  return newState.setIn(['suggestions', 'citations'], fromJS(suggestions));
}

function addSuggestions(state, action) {

  let newState = state;

  const thereAreSuggestions =
    action.payload.suggestions &&
    action.payload.suggestions.length > 0;

  if (thereAreSuggestions) {

    if (!Array.isArray(action.payload.suggestions)) {
      throw new Error(`Expected suggestions to be an array.`
        + ` Got ${action.payload.suggestions.constructor}`);
    }

    const immutableSuggestions = fromJS(action.payload.suggestions);
    newState = newState.mergeIn(['suggestions', 'all'], immutableSuggestions);
  }

  return newState;
}

function toggleSuggestion(state, action) {
  const { suggestionUUID } = action.payload;

  const idx = _indexForSuggestionWithUUID(suggestionUUID, state);
  const accepted = state.getIn(['suggestions', 'all', idx, 'accepted']);

  return accepted ?
    undoAcceptSuggestion(state, action) :
    acceptSuggestion(state, action);
}

function acceptSuggestion(state, action) {
  let newState = state;

  const { suggestionUUID, replacement } = action.payload;

  const idx = _indexForSuggestionWithUUID(suggestionUUID, newState);
  if (idx !== -1) {
    newState = newState.setIn(
      ['suggestions', 'all', idx, 'accepted'],
      true,
    );
    newState = newState.setIn(
      ['suggestions', 'all', idx, 'acceptedValue'],
      replacement,
    );
  }

  return newState;
}

function undoAcceptSuggestion(state, action) {
  let newState = state;

  const { suggestionUUID } = action.payload;

  const idx = _indexForSuggestionWithUUID(suggestionUUID, newState);
  if (idx !== -1) {
    newState = newState.setIn(
      ['suggestions', 'all', idx, 'accepted'],
      false,
    );
    newState = newState.setIn(
      ['suggestions', 'all', idx, 'acceptedValue'],
      null,
    );
  }

  return newState;
}

function _indexForSuggestionWithUUID(UUID, state) {
  return state.getIn(['suggestions', 'all']).findIndex(suggestion =>
    suggestion.get('suggestionUUID') === UUID);
}

function onDownloadDocEnd(state, action) {
  const { didNotApply } = action.payload;
  let newState = state;
  newState = newState.setIn(
    ['suggestions', 'didNotApply'],
    didNotApply ? state.getIn(['suggestions', 'all']).filter(s =>
      didNotApply.find(x => x == s.get('suggestionUUID'))) :
      [],
  );
  return newState;
}