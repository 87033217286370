/**
 * ModalBehavior.
 */
export default superclass => class extends superclass {

  constructor() {
    super(...arguments);
    this.ui.showModal = this._showModal.bind(this);
  }

  /*
   * Show `html` in a modal view.
   * @param {*} html a lit-html template.
   */
  _showModal(html) {
    this.dispatch({
      type: 'show_modal',
      payload: { html },
    });
  }

  /*
   * Invoked when the close animation ends.
   */
  _onModalClosed(e) {
    // Update state.
    this.dispatch({ type: 'hide_modal' });
  }

}
