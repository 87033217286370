import Immutable from 'immutable';
const { fromJS } = Immutable;
import uuid from 'uuid/v4';
import initialState from '~/data/state.js';
import http from 'superagent';


/**
 * InitBehavior
 */
export default superclass => class extends superclass {

  constructor() {
    super();
    this.state = Immutable.fromJS(initialState);
  }

  connectedCallback() {
    super.connectedCallback(...arguments);
    this._init();
  }

  async _init() {
    const params = new URLSearchParams(window.location.search);

    if (params.get('dev') === '1') {
      window.dev = true;
    }

    if (params.get('token')) {
      this.dispatch({
        type: 'set_token',
        payload: { token: params.get('token') },
      });
    }

    if (params.get('thresholdFilter') === '1') {
      window.showThresholdFilter = true;
    }

    // Fetch runtime config.
    const cfgEntry = window.location.hostname
      .replace('cite-', 'revise-')
      .split('.')[0]
    let cfgRes;
    try {
      cfgRes = await http
        .get(`https://cfg.writefull.ai/${cfgEntry}.json`);
    } catch (error) {
      console.warn(`Unable to load runtime config`);
      cfgRes = { body: {} };
    }

    // Move to the initial page.
    let page = cfgRes.body.page || this.state.get('page') || 'upload';
    this.dispatch({ type: 'go_to_page', payload: { page } });

    // Check if a callback URL was provided.
    let callbackURL = params.get('callbackURL') || params.get('callback');
    if (callbackURL) {
      callbackURL = decodeURIComponent(callbackURL);
      this.dispatch({ type: 'update_callback_url', payload: { callbackURL } });
    }

    // Generate a session UUID
    const sessionUUID = uuid();
    window.sessionUUID = sessionUUID;
    this.dispatch({ type: 'set_session_uuid', payload: { uuid: sessionUUID } });

    try {
      mixpanel.identify(sessionUUID);
      mixpanel.register({
        dev: window.dev,
        version: 3,
      });
      mixpanel.track('landed');
    } catch (error) { }

    // Fetch the paper ID from the path.
    let UUID = null;
    try {
      UUID = window.location.pathname.split('/').slice(-1)[0];
    } catch (error) { }

    if (UUID) {
      return this._restoreState(UUID);
    } else {
      // Waiting for the user to upload a paper.
      // TODO: Set state accordingly.
      return;
    }
  }

  async _dispatchDocumentUpdateFromText(text) {

    // Divide the text into its atomic unit of correction.
    let parts = await this.paragraphs(text);

    // TODO: The server should be sending the UUID.
    parts = parts.map(p => ({ UUID: uuid(), text: p }))

    // TODO: Implement properly.
    // Assume that the first part is the title.
    let title = parts[0].text;

    // Determine whether the first line of text is a title.
    const lengthOfTitle = title.split(' ').length; // tokens
    if (lengthOfTitle <= 3 || lengthOfTitle > 20) {
      title = null; // not a title.
    }

    this.dispatch({
      type: 'update_document',
      payload: { title, originalText: text, parts },
    });
  }

}
