import { LitElement, html, css } from "lit-element";
import "@material/mwc-button";
import "@material/mwc-checkbox";
import { sharedStyles } from "~/elements/shared-styles/shared-styles.js";
import { infoPageStyles } from "~/elements/shared-styles/info-page-styles";

class UploadView extends LitElement {
  static get properties() {
    return {
      uploading: { type: Boolean, reflect: true },
      showUploadButton: { type: Boolean, reflect: true, attribute: 'show-upload-button' },
      acceptedTerms: { type: Boolean },
    };
  }

  constructor() {
    super(...arguments);
    this.uploading = false;
    this.showUploadButton = false;
    this.acceptedTerms = false;
  }

  _onUploadClick(e) {
    try {
      mixpanel.track('upload')
    } catch (error) { }
    this.fileUploadButton.click();
  }

  _onDemoClick(e) {
    e.preventDefault();
    e.stopPropagation();
    this.dispatchEvent(new CustomEvent('guide-request', {
      bubbles: true,
      composed: true,
      detail: {},
    }));
  }

  _onTermsClick(e) {
    try {
      mixpanel.track('click-terms');
    } catch (error) { }
  }

  async _handleFileChange(e) {
    const dataURL = await this._getDataURL();
    this.dispatchEvent(
      new CustomEvent("upload-file", {
        bubbles: true,
        composed: true,
        detail: {
          dataURL,
          filename: this.fileUploadButton.files[0].name,
          size: this.fileUploadButton.files[0].size,
        },
      })
    );
    // Reset so that the `change` event is triggered if the user reuploads the
    // same document.
    this.fileUploadButton.value = '';
  }

  async _getDataURL() {
    return new Promise((resolve, reject) => {
      const fileList = this.fileUploadButton.files;
      const file = fileList[0];
      const reader = new FileReader();
      reader.addEventListener("load", e => {
        resolve(e.target.result);
      });
      reader.readAsDataURL(file);
    });
  }

  get fileUploadButton() {
    if (!this.__fileUploadButton) {
      this.__fileUploadButton = this.shadowRoot.querySelector("#fileUpload");
    }
    return this.__fileUploadButton;
  }

  static get styles() {
    return [
      sharedStyles,
      infoPageStyles,
      css`
        :host {
          display: block;
        }

        :host([hidden]) {
          display: none;
        }

        #accept-terms-container {
          font-weight: var(--font-weight-light);
          display: flex;
          flex-flow: row nowrap;
          justify-content: center;
          align-items: center;
          margin-bottom: var(--padding);
        }

        #supported-filetypes {
          margin-top: var(--padding-sm);
          font-family: var(--font-family-secondary);
          font-weight: 100;
          font-size: 0.9em;
          color: gray;
        }
        `
    ];
  }

  _updateTermsAcceptance() {
    this.acceptedTerms =
      this.shadowRoot.querySelector('#termsCheckbox').checked;
  }

  render() {
    return html`
      <div id="container">
        <div id="main">
          <!-- TODO(hilde) -->
          <h3 class="header">Stand on the shoulders of giants... but cite them.</h3>
          <span id="main-copy">
          Use Writefull to check your manuscript for missing citations.
          </span>
          <br />
        </div>

        ${this.showUploadButton ? html`
          <div id="accept-terms-container">
            <mwc-checkbox
              id="termsCheckbox"
              @change=${e => this._updateTermsAcceptance()}></mwc-checkbox>
              <span>I understand and accept the <a @click=${e => this._onTermsClick(e)} href="https://writefull.com/terms.html" target="_blank">Terms of Service.</a></span>
          </div>
          <mwc-button
            id="uploadDoc"
            ?disabled=${this.uploading || !this.acceptedTerms}
            @click=${e => this._onUploadClick(e)}
            label="Upload Manuscript"
            outlined></mwc-button>
          <div id="supported-filetypes">.docx, .doc or .tex</div>
          <input
            id="fileUpload"
            type="file"
            hidden
            @change=${e => this._handleFileChange(e)}
          />
        ` : ''}
      </div>
    `;
  }
}
customElements.define("upload-view", UploadView);
