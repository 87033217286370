import Immutable from 'immutable';
const { fromJS } = Immutable;

export default function stateReducer(state, action) {
  switch (action.type) {
    case 'update_state':
      return updateState(state, action);
    case 'set_session_uuid':
      return onSetSessionUUID(state, action);
    case 'update_callback_url':
      return state.set('callbackURL', action.payload.callbackURL);
    case 'set_token':
      return state.set('token', action.payload.token);
    case 'restore_state':
      return restoreState(state, action);
    case 'set_citations_threshold':
      return setCitationsThreshold(state, action);
    default:
      return state;
  }
}

function updateState(state, action) {
  return state.mergeDeep(fromJS(action.payload.state));
}


function setCitationsThreshold(state, action) {
  let newState = state;
  newState = state.set('citationsThreshold', action.payload.threshold);
  console.log('Updating citations threshold');
  console.dir(newState.toJS())
  return newState;
}

function restoreState(state, action) {
  let newState = state;
  const targetState = fromJS(action.payload.state);

  newState = targetState.set('ephemeral', state.get('ephemeral'));

  return newState;
}

function onSetSessionUUID(state, action) {
  if (state.get('sessionUUID') != null) {
    throw new Error(`Attempted to reset the session UUID for an existing session.`)
  }
  let newState = state;
  newState = newState.set('sessionUUID', action.payload.uuid);
  newState = newState.set('sessionStart', String(new Date().getTime()));
  return newState;
}