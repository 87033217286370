import { LitElement, html, css } from "lit-element";

class AnnotationView extends LitElement {
  static get styles() {
    return css`
      :host {
        display: inline;
        --background-color: white;
        --annotation-padding: 1px 4px;
      }

      :host([hidden]) {
        display: none;
      }

      #container {
        display: inline;
        background-color: var(--background-color);
        color: var(--text-color, black);
        padding: var(--annotation-padding);
        border-radius: 4px;
        font-size: var(--font-size-xs, 11px);
        font-weight: 400;
        text-transform: uppercase;
      }
    `;
  }

  render() {
    return html`
      <div id="container"><slot></slot></div>
    `;
  }
}
customElements.define("annotation-view", AnnotationView);
