import Immutable from 'immutable';
const { fromJS } = Immutable;


export default function userReducer(state, action) {
  switch (action.type) {
    case 'set_user':
        return setUser(state, action);
    default:
      return state;
  }
  
}

function setUser(state, action) {
  let newState = state;
  const properties = fromJS(action.payload || {});
  newState = newState.mergeIn(['user'], fromJS(properties));
  return newState;
}