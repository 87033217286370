import { html } from 'lit-html';
import '~/elements/suggestion-tag/suggestion-tag.js';
import download from 'downloadjs';


/**
 * HeaderViewDelegate.
 */
export default superclass => class extends superclass {

  _triggerDownload(docx, filename) {
    const dataURL = `data:application/vnd.openxmlformats-officedocument.wordprocessingml.document;base64,${docx}`;
    download(dataURL, filename);
  }

  async _onDownloadRequest(e) {

    if (this.state.get('page') === 'download') {
      const data = this.state.getIn(['document', 'patched']);
      const filename = this.state.getIn(['document', 'filenameOfPatchedDoc']);
      return this._triggerDownload(data, filename);
    }

    // If we are not in the download page, prepare a new download.

    const suggestions = this.state
      .getIn(['suggestions', 'all'])
      .toJS();

    const { UUID, filename, dataURL } = this.state.get('document').toJS();

    if (process.env.NODE_ENV === 'development') {
      console.table(suggestions);
    }

    let patchedDoc = null;
    let didNotApply = null;
    let filenameOfPatchedDoc = '';
    try {
      this.dispatch({ type: 'prepare_download_doc_start' });

      if (process.env.NODE_ENV === 'development') {
        console.warn(`TODO: Only patch supported formats.`)
      }

      // Send the docx and suggestions. 
      const res = await this.http.post(process.env.patch_url).send({
        docx: dataURL.includes(',') ? dataURL.split(',')[1] : dataURL,
        suggestions,
        sessionUUID: window.dev ? undefined : this.state.get('sessionUUID'),
        sessionStart: this.state.get('sessionStart'),
        callbackURL: this.state.get('callbackURL'),
      });
      if (res.body.result && res.body.result === 'Failed to patch the document') {
        this.ui.notify('This document could not be patched.');
        this.dispatch({ type: 'go_to_page', payload: { page: 'report' } });
        return;
      }
      patchedDoc = res.body.docx;

      // Check if some suggestions were not applied.
      if (res.body.cannot_apply && res.body.cannot_apply.length) {
        didNotApply = res.body.cannot_apply;
      }

      // Compute the name of the patched document.
      filenameOfPatchedDoc = filename ? // TODO: Support formats other than .docx
        `${filename.split('.docx')[0]}_writefull.docx` : 'writefull-suggestions.docx';

    } catch (error) {
      console.error(error);
    } finally {
      this.dispatch({
        type: 'prepare_download_doc_end',
        payload: {
          didNotApply,
          filenameOfPatchedDoc,
          patchedDoc,
        },
      });
    }

  }
}
