import { LitElement, html, css } from "lit-element";
import { sharedStyles } from "~/elements/shared-styles/shared-styles.js";
import { infoPageStyles } from "~/elements/shared-styles/info-page-styles.js";
import "~/elements/continue-button/continue-button.js";
import "~/elements/nav-buttons/nav-buttons.js";


class DownloadView extends LitElement {

  static get properties() {
    return {
      filename: { type: String },
      suggestionsThatCouldNotBeApplied: { type: Array },
      showExplanation: { type: Boolean },
    };
  }

  static get styles() {
    return [
      sharedStyles,
      infoPageStyles,
      css`
        :host { display: block; }
        :host([hidden]) { display: none; }

        #unapplied-suggestions,
        .explanation {
          font-size: var(--font-size);
        }

        #unapplied-suggestions {
          margin-top: var(--padding-xl);
          padding: var(--padding);
          background: #f7f7f7;
          border-radius: var(--padding-sm);
          border: 1px solid #c7c7c7;
          max-height: 80vh;
          overflow-y: scroll;
        }

        #unapplied-suggestions p:not(:first-of-type) {
          border-top: 2px dashed lightgray;
          padding-top: var(--padding);
        }

        #show-explanation {
          color: gray;
          font-size: var(--font-size-xl);
          text-decoration: none;
        }

        #show-explanation:hover {
          text-decoration: underline;
          cursor: pointer;
          color: var(--text-color);
        }
      `
    ];
  }

  constructor() {
    super(...arguments);
    this.filename = '';
    this.suggestionsThatCouldNotBeApplied = [];
    this.showExplanation = false;
  }

  firstUpdated() {
    super.firstUpdated(...arguments);
    window.scrollTo({ top: 0 });
  }

  _onDownloadClick(e) {
    try {
      mixpanel.track('download');
    } catch (error) { }

    this.dispatchEvent(new CustomEvent('download-request', {
      bubbles: true,
      composed: true,
      detail: {},
    }));
  }

  render() {
    let { filename, suggestionsThatCouldNotBeApplied, showExplanation } = this;
    console.log('cannot apply:');
    console.dir(suggestionsThatCouldNotBeApplied);
    suggestionsThatCouldNotBeApplied = suggestionsThatCouldNotBeApplied || [];

    const unappliedFragment = suggestionsThatCouldNotBeApplied.map(s => {
      // TODO: Handle the case where the context appears more than once.
      const start = s.index;
      const before = s.sentenceText.slice(0, start);
      const after = s.sentenceText.slice(start + s.length);
      return html`
        <p>${before}<suggestion-tag remove=${s.target} add=${s.acceptedValue} .interactive=${false}></suggestion-tag>${after}</p>
      `;
    });

    const filenameMaxLengthPerLine = 80;
    if (filename.length > filenameMaxLengthPerLine) {
      filename = `${filename.slice(0, filenameMaxLengthPerLine)}...`;
    }

    return html`
      <nav-buttons .left=${{ label: "Review accepted suggestions" }}></nav-buttons>
      <div id="container">
        <div id="main">
          <h3 class="header">You are almost there...</h3>
          ${!filename.length ? html`
            <p>We are generating your revised document.</p>
          ` : ''}
          ${filename.length ? html`
            <p>Writefull has generated a new document that includes the changes you have accepted:<br><br><b>${filename}</b></p>
            ${suggestionsThatCouldNotBeApplied.length ? html`
              <p>In this document, <span style="text-decoration: underline;"><b>${suggestionsThatCouldNotBeApplied.length}</b> ${suggestionsThatCouldNotBeApplied.length > 1 ? 'suggestions' : 'suggestion'} could not be applied.</span> You can include these into your text by editing the document manually.
              <span id="show-explanation" @click=${e => this.showExplanation = !showExplanation}>(${showExplanation ? 'Hide' : 'Read'} why)</span></p>
              ${showExplanation ? html`
                <p class="explanation">Writefull does its best to apply all of the suggestions you've accepted to your document. Unfortunately, docx is not a well-behaved format, and in some cases, suggestions cannot be applied. Below, you see the suggestions that you've accepted, but that Writefull was unable to apply.</p>
              ` : ''}
              <div id="unapplied-suggestions">${unappliedFragment}</div>
            ` : ''}
            <div id="next-step-container">
              <continue-button label="Download Revised Manuscript" @continue=${e => this._onDownloadClick(e)}></continue-button>
            </div>
          ` : ''}
        </div>
      </div>
    `;
  }
}
customElements.define('download-view', DownloadView);