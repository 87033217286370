/**
 * PersistStateBehavior
 */
export default superclass => class extends superclass {

  async _saveState() {
    const state = JSON.stringify(this.state.toJS());
    const res = await this.http.put(process.env.doc_url).send({ state });
    const { UUID } = res.body;
    window.location.pathname = UUID;
  }

  async _restoreState(docId) {
    const res = await this.http.get(`${process.env.doc_url}/${docId}`);
    const state = JSON.parse(res.body.state)
    this.dispatch({
      type: 'restore_state',
      payload: { state },
    });
  }

}