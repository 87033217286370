import Immutable from 'immutable';
const { fromJS } = Immutable;

const aliases = Object.freeze({
  'spell': 'spelling',
  'commas': 'punctuation',
  'citation': 'citations',
});

export default function metricsReducer(state, action) {
  // Ignore the action, always update the metrics
  return updateMetrics(state, action);
}


export function updateMetrics(state, action = null) {
  // Create a dict mapping the normalized metric name to its count, i.e.
  // { 'grammar': 0, 'style': 0, ... }
  const counts = {};
  state.get('metrics').toJS().forEach(metric =>
    counts[metric.name.toLowerCase()] = 0);

  const suggestions = state.getIn(['suggestions', 'citations']).toJS().filter(s =>
    s.acceptedValue == null);
  const metricNames = Object.keys(counts);

  // Count suggestions based on metric type.
  suggestions.forEach(s => {

    const normalizedSuggestionType = s.type.toLowerCase();
    const detailedType = normalizedSuggestionType.includes(':') ?
      normalizedSuggestionType.split(':').slice(-1) : normalizedSuggestionType;

    if (detailedType in aliases) {
      ++counts[aliases[detailedType]];
    } else {

      // Try to find a metric for the detailed type. For example,
      // in the type `grammar:noun-number` `noun-number` is the detail.
      let foundMetric = false;
      for (const metricName of metricNames) {
        if (detailedType.includes(metricName)) {
          ++counts[metricName];
          foundMetric = true;
          break;
        }
      }

      // Try to find a metric for the generic type. For example, `grammar` in
      // `grammar:noun-number`.
      if (!foundMetric) {
        for (const metricName of metricNames) {
          if (normalizedSuggestionType.includes(metricName)) {
            ++counts[metricName];
            break;
          }
        }
      }

    }
  });

  // Update the value for each metric.
  let newState = state;
  const newMetrics = state.get('metrics').map(metric => {
    return metric.set('value', counts[metric.get('name').toLowerCase()]);
  });

  newState = newState.set('metrics', fromJS(newMetrics));
  return newState;
}
