import tokenizer from 'sbd';
import uuid from 'uuid/v4';
import reflect from 'p-reflect';
import Queue from 'promise-queue';
import flatten from 'lodash.flatten';
import chunk from 'lodash.chunk';


const DEBUG = process.env.NODE_ENV === 'development';

const batchSize = Number(process.env.suggest_batch_size);
const maxConcurrent = Number(process.env.suggest_max_concurrent);
const maxQueue = Infinity;
const queue = new Queue(maxConcurrent, maxQueue);

if (DEBUG) {
  console.log(`API: ${process.env.writefull_suggest_url}`);
  console.log(`Max concurrent: ${maxConcurrent}`);
  console.log(`batch size ${batchSize}`);
}

/**
 * SuggestBehavior
 * 
 * Encapsulate behavior to fetch and normalize suggestions, handle suggestion
 * clicks, etc.
 */
export default superclass => class extends superclass {

  _onAcceptReplacement(e) {
    try {
      if (!window.firstSuggestionAccepted) {
        mixpanel.track('accept-first-suggestion');
      }
      window.firstSuggestionAccepted = true;
    } catch (error) { }
    this._toggleSuggestion(e);
  }

  _onUndoAcceptReplacement(e) {
    this._toggleSuggestion(e);
  }

  _toggleSuggestion(e) {
    const { suggestionUUID, replacement } = e.detail;
    this.dispatch({
      type: 'toggle_suggestion',
      payload: { suggestionUUID, replacement },
    });
  }

  async suggest(sentences, options) {
    const opts = Object.assign({
      retry: 1,
    }, options);

    let suggestionsForSentences = [];

    await queue.add(async () => {
      if (DEBUG) { console.log(`queue length: ${queue.getQueueLength()} ; pending: ${queue.getPendingLength()}`); }
      if (DEBUG) { console.log(`${+new Date()} Fetching suggestions for ${sentences.length} sentences`) }
      try {
        const tic = new Date();
        suggestionsForSentences =
          await this._suggestionsForSentences(sentences, opts);
        if (!suggestionsForSentences) {
          console.warn(`Did not receive suggestions.`);
        }
        const toc = new Date();
        if (DEBUG) { console.log(`Took ${toc - tic} for ${sentences.length} sentences`); }
      } catch (error) {
        console.error(error);
      }

    });

    const errors = suggestionsForSentences.filter(s => s.error != null);
    if (DEBUG && errors.length) {
      console.error(errors);
    }
    const suggestions = suggestionsForSentences.filter(s => s.error == null);

    return suggestions;
  }

  async _sentencesFromParts(parts) {
    const sentences = [];
    try { // Try to use the external sentence boundary detector.
      const res = await this.http
        .post(process.env.sbd_url)
        .set({
          // TODO: Use other auth method.
          'beta-token': process.env.beta_token,
          'beta-device-id': process.env.beta_device_id,
        })
        .timeout(5000)
        .send(parts.map(part => part.text));
      if (res.body.length !== parts.length) {
        throw new ValueError(
          `The response length does not match the length of the parts`);
      }
      res.body.forEach((sentencesInPart, idx) => {
        const partUUID = parts[idx].UUID;
        sentencesInPart.forEach(sentence => {
          sentences.push({
            sentenceText: sentence,
            partUUID,
          })

        })
      })
    } catch (error) { // Fall-back to the internal one.
      if (window.dev) {
        console.error(error);
        console.warn(`Using internal sbd`);
      }
      parts.forEach(part => {
        const _sentences = tokenizer.sentences(part.text)
        _sentences.forEach(sentenceText => {
          sentences.push({
            sentenceText,
            partUUID: part.UUID,
          });
        });
      });
    }
    // Sentences looks like this:
    // [{ partUUID, sentenceText}, { partUUID, sentenceText }, ...]
    return sentences;
  }

  async __suggestCitations() {
    const parts = this.state.getIn(['document', 'parts']).toJS();

    let sentences = await this._sentencesFromParts(parts);
    sentences = sentences.map(s => {
      s.sentenceUUID = uuid();
      return s;
    });

    const citationSuggestions = [];

    for (const sentence of sentences) {
      const { sentenceUUID } = sentence;

      try {
        this.dispatch({
          type: 'correct_part_start',
          payload: { UUID: sentenceUUID },
        });

        console.log(process.env.citations_url);

        const res =
          await this.http.post(process.env.citations_url)
            .send([sentence.sentenceText])
            .timeout({ // TODO: Adjust these.
              response: process.env.suggest_response_timeout,
              deadline: process.env.suggest_deadline_timeout,
            });

        // FIXME
        const unwrappedResults = res.body.result.map(so => so.suggestions)
        const normalizedResults = unwrappedResults.map((r, idx) => {
          const {
            partUUID,
            sentenceUUID,
            sentenceText,
          } = sentences[idx];
          r.suggestions = r.suggestions.map(s => Object.assign({}, s, {
            suggestionUUID: uuid(),
            partUUID,
            sentenceUUID,
            sentenceText,
            target: s.word,
          }))
          return r;
        })

        const citationResults = normalizedResults.filter(r =>
          r.suggestions && r.suggestions.length)

        citationSuggestions.push(...citationResults.map(r => r.suggestions[0]))

      } catch (error) {
        console.error('Failed getting citations for a sentence');
        console.error(error);
      } finally {
        this.dispatch({
          type: 'correct_part_end',
          payload: { UUID: sentenceUUID },
        });
      }
    }

    if (citationSuggestions.length) {
      this.dispatch({
        type: 'add_suggestions',
        payload: {
          suggestions: citationSuggestions.map(s => {
            s.type = 'citation';
            return s;
          }),
        },
      });
    }

  }


  async suggestCitations() {
    const parts = this.state.getIn(['document', 'parts']).toJS();
    const sentences = await this._sentencesFromParts(parts);
    const batches = chunk(sentences, batchSize);

    if (DEBUG) { console.log(`Correcting ${sentences.length} sentences`); }
    try {
      mixpanel.track('started-correcting', { numOfSentences: sentences.length });
    } catch (error) { }

    const tic = new Date();

    const promises = batches.map(async batch => new Promise(async (resolve, reject) => {

      const batchUUID = uuid();

      try {
        // Register the request.
        this.dispatch({
          type: 'correct_part_start',
          payload: { UUID: batchUUID, batch },
        });

        const sentencesInBatch = batch.map(s => s.sentenceText);

        // const suggestionsForBatch = await this.suggest(sentencesInBatch);

        // if (suggestionsForBatch.length !== batch.length) {
        //   throw new Error(`Received an invalid number of suggestions.`);
        // }

        const citationsResponse =
          await this.http.post(process.env.citations_url)
            .send(sentencesInBatch)
            .timeout({ // TODO: Adjust these.
              response: process.env.suggest_response_timeout,
              deadline: process.env.suggest_deadline_timeout,
            });
        const citationsForBatch = citationsResponse.body.result.map(r => {
          if (r.suggestions && r.suggestions.suggestions) {
            r.suggestions = r.suggestions.suggestions;
          }
          return r;
        });

        if (citationsForBatch.length !== batch.length) {
          throw new Error(`Received an invalid number of suggestions.`);
        }

        // const normalizedSuggestions = [];
        // suggestionsForBatch.forEach((suggestionsForSentence, idx) => {
        //   if (suggestionsForSentence.suggestions.length) {
        //     const normalized = this._normalizeSuggestionsForSentence(
        //       suggestionsForSentence,
        //       batch[idx].partUUID,
        //     );
        //     if (normalized && normalized.length) {
        //       normalizedSuggestions.push(...normalized);
        //     }
        //   }
        // });
        // const suggestions = normalizedSuggestions;

        const normalizedCitations = [];
        citationsForBatch.forEach((citationsForSentence, idx) => {
          if (citationsForSentence.suggestions.length) {
            const normalized = this._normalizeSuggestionsForSentence(
              citationsForSentence,
              batch[idx].partUUID,
            );
            if (normalized && normalized.length) {
              normalizedCitations.push(...normalized);
            }
          }
        });
        const suggestions = normalizedCitations;


        if (suggestions && suggestions.length) {
          this.dispatch({
            type: 'add_suggestions',
            payload: { suggestions },
          });
        }
        resolve(suggestions);
      } catch (error) {
        console.error(error);
        console.error(`Failed fetching suggestions for batch ${batchUUID}`);
        reject(error);
      } finally {
        // Deregister the request.
        this.dispatch({
          type: 'correct_part_end',
          payload: { UUID: batchUUID, batch },
        });
      }
    }));

    await Promise.all(promises.map(p => reflect(p)));


    const timeToCorrect = (new Date()) - tic;

    try {
      mixpanel.track('finished-correcting', { timeToCorrect })
    } catch (error) { }

    if (DEBUG) { console.log(`Correcting the paper took ${timeToCorrect}`); }
  }


  /**
   * Corrects the currently loaded paper.
   */
  async correctPaper() {
    const parts = this.state.getIn(['document', 'parts']).toJS();
    const sentences = await this._sentencesFromParts(parts);
    const batches = chunk(sentences, batchSize);

    if (DEBUG) { console.log(`Correcting ${sentences.length} sentences`); }
    try {
      mixpanel.track('started-correcting', { numOfSentences: sentences.length });
    } catch (error) { }

    const tic = new Date();

    const promises = batches.map(async batch => new Promise(async (resolve, reject) => {

      const batchUUID = uuid();

      try {
        // Register the request.
        this.dispatch({
          type: 'correct_part_start',
          payload: { UUID: batchUUID, batch },
        });

        const sentencesInBatch = batch.map(s => s.sentenceText);

        const suggestionsForBatch = await this.suggest(sentencesInBatch);

        if (suggestionsForBatch.length !== batch.length) {
          throw new Error(`Received an invalid number of suggestions.`);
        }

        const normalizedSuggestions = [];
        suggestionsForBatch.forEach((suggestionsForSentence, idx) => {
          if (suggestionsForSentence.suggestions.length) {
            const normalized = this._normalizeSuggestionsForSentence(
              suggestionsForSentence,
              batch[idx].partUUID,
            );
            if (normalized && normalized.length) {
              normalizedSuggestions.push(...normalized);
            }
          }
        });

        const suggestions = normalizedSuggestions;

        if (suggestions && suggestions.length) {
          this.dispatch({
            type: 'add_suggestions',
            payload: { suggestions },
          });
        }
        resolve(suggestions);
      } catch (error) {
        console.error(error);
        console.error(`Failed fetching suggestions for batch ${batchUUID}`);
        reject(error);
      } finally {
        // Deregister the request.
        this.dispatch({
          type: 'correct_part_end',
          payload: { UUID: batchUUID, batch },
        });
      }
    }));

    await Promise.all(promises.map(p => reflect(p)));


    const timeToCorrect = (new Date()) - tic;

    try {
      mixpanel.track('finished-correcting', { timeToCorrect })
    } catch (error) { }

    if (DEBUG) { console.log(`Correcting the paper took ${timeToCorrect}`); }
  }

  async _suggestionsForSentences(sentences, options) {
    const opts = options;
    try { // Fetch suggestions for one sentence.
      const res = await this.http.post(process.env.writefull_suggest_url)
        .set({
          // TODO: Use other auth method.
          'beta-token': process.env.beta_token,
          'beta-device-id': process.env.beta_device_id,
        })
        .send(sentences)
        .timeout({ // TODO: Adjust these.
          response: process.env.suggest_response_timeout,
          deadline: process.env.suggest_deadline_timeout,
        })
        .retry(opts.retry)
      return res.body.result;
    } catch (error) {
      console.error(error);
      console.error(sentences);
      console.error(`Failed fetching suggestions for sentences.`)
      return [];
    }
  }

  /**
   * Convert suggestions in `suggestionsForSentence` to the v2 format.
   * @param {*} suggestionsForSentence 
   * @param {*} partUUID v2 of the suggestions has a key identifying the part
   * it belongs to.
   */
  _normalizeSuggestionsForSentence(suggestionsForSentence, partUUID) {
    const sentenceText = suggestionsForSentence.text;
    const normalized = suggestionsForSentence.suggestions.map(s => {

      // Add some new keys to the suggestions to aid in the correction process.
      const _s = {
        ...s,
        sentenceText,
        suggestionUUID: uuid(),
        partUUID,
        accepted: null,
      };

      // Rename `suggestions` -> `replacements`
      if (_s.suggestions) {
        _s.replacements = _s.suggestions;
        delete _s.suggestions;
      }

      // Rename `word` -> `target`
      if (_s.word) {
        _s.target = _s.word;
        delete _s.word;
      }

      return _s;
    });

    return normalized;
  }

  /**
   * Split a text into paragraphs.
   * @param {string} text 
   */
  async paragraphs(text) {
    return text.split('\n').filter(s => s.trim().length > 0);
  }

}
