import { css } from 'lit-element';

export const sharedStyles = css`
  :host {
    --mdc-theme-primary: black;
    --mdc-theme-secondary: black;
  }

  :host {
    font-family: var(--font-family);
  }

  #next-step-container {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    align-items: flex-end;
    height: 100px;
  }

  .centered {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    align-items: center;
  }

  .right {
    justify-content: flex-end;
  }

  .left {
    justify-content: flex-start;
  }

  .button {
    cursor: pointer;
  }

  .chevron {
    max-width: 14px;
    max-height: 14px;
  }
`;