import { LitElement, html, css } from 'lit-element';
import Immutable from 'immutable';
import http from 'superagent';

// Elements
import '@polymer/paper-toast';
import '~/elements/header-view/header-view.js';
import '~/elements/report-view/report-view.js';
import '~/elements/modal-view/modal-view.js';

// Behaviors
import InitBehavior from './behaviors/init.js';
import FetchPaperBehavior from './behaviors/fetch-paper.js';
import SuggestBehavior from './behaviors/suggest.js';
import HeaderViewDelegate from './behaviors/header-view-delegate.js';
import StateManagerBehavior from './behaviors/state-manager.js';
import UIBehavior from './behaviors/ui-behavior.js';
import ModalBehavior from './behaviors/modal-behavior.js';
import GuideBehavior from './behaviors/guide-behavior.js';
import TermsBehavior from './behaviors/terms-behavior.js';
import NavigationBehavior from './behaviors/navigation-behavior.js';
import AuthBehavior from './behaviors/auth-behavior.js';

// WritefullReport inherits from all of the following.
const Mixin = [
  LitElement,
  UIBehavior,
  StateManagerBehavior,
  NavigationBehavior,
  HeaderViewDelegate,
  FetchPaperBehavior,
  SuggestBehavior,
  ModalBehavior,
  GuideBehavior,
  TermsBehavior,
  InitBehavior,
  AuthBehavior,
].reduce((a, b) => b(a));


/**
 * WritefullReport
 */
class WritefullReport extends Mixin {

  static get properties() {
    return {
      state: { type: Object },
    };
  }

  constructor() {
    super(...arguments);
    this.http = http;
  }

  static get styles() {
    return css`
      :host {
        display: block;
      }
      :host([hidden]) { display: none; }

      #container {
        display: flex;
        flex-flow: row nowrap;
        max-width: var(--max-width);
        min-width: var(--min-width);
        margin: 0 auto;
      }

      header-view {
        position: fixed;
        top: 0;
        right: 0;
        left: 0;
        z-index: 1000;
        min-width: 1100px;
        height: var(--header-height);
        border-bottom: rgba(0, 0, 0, 0.06) 1px solid;
        background: rgba(255, 255, 255, 0.95);
      }

      report-view {
        margin-top: var(--header-height);

        flex: 1;

        overflow-x: hidden;
      }

      report-view.report-page {
        margin-top: calc(var(--header-height) + var(--padding));
        margin-bottom: calc(var(--header-height) + var(--padding-xl));
      }

      paper-toast {
        font-family: var(--font-family);
        z-index: 10000;
      }

      modal-view {
        z-index: 9995;
      }

      #tutorial h2,
      #tutorial h3 {
        color: var(--text-color);
        font-family: var(--font-family-secondary);
        font-weight: 500;
      }

      #tutorial h2 {
        margin-top: var(--padding-xl);
      }

      #tutorial h3 {
        margin-bottom: calc(-1*var(--padding-sm));
        font-style: italic;
      }

      #tutorial p {
        font-family: var(--font-family);
        font-weight: var(--font-weight-light);
        font-size: var(--font-size);
        color: var(--text-color);
        line-height: 1.5em;
      }

      #tutorial img {
        max-width: 600px;
      }
    `;
  }

  render() {
    let {
      page,
      document,
      metrics,
      suggestions,
      ephemeral,
      citationsThreshold,
    } = this.state.toJS();
    const {
      UUID,
      title,
      originalText,
      parts,
      canBePatched,
      isDotDoc,
    } = document;
    const {
      citationsSeeFullDoc,
    } = ephemeral;
    const isUploadingDocument = ephemeral.uploadingDocument;
    const isDownloadingDocument = ephemeral.downloadingDocument;

    const isLoading = (
      ephemeral.suggestRequests.length > 0 || isUploadingDocument
    );

    // Make `part.suggestions` an empty array if undefined.
    let partsWithSuggestions = (parts || []).map(part => {
      part.suggestions = part.suggestions || [];
      return part;
    });

    suggestions = suggestions.citations

    // Assign each suggestion to its corresponding part.
    if (suggestions && suggestions.length) {
      suggestions.forEach(s => {
        const partUUID = s.partUUID;
        const part = partsWithSuggestions.find(part => part.UUID === partUUID);
        if (!part) {
          return console.error(`Unable to find part ${s.partUUID}`);
        }
        part.suggestions.push(s);
      });
    }

    // Add the part number.
    partsWithSuggestions = partsWithSuggestions.map((p, idx) => {
      p.number = idx + 1;
      return p;
    });

    if (citationsSeeFullDoc) {
    } else {
      partsWithSuggestions = partsWithSuggestions.filter(p =>
        p.suggestions.length)
    }

    const canDownload = false;

    const breadcrumbs = [
      { identifier: 'upload', label: 'Upload your manuscript' },
      { identifier: 'report', label: 'Check and fix' },
      { identifier: 'download', label: 'Download' },
      { identifier: 'continue-to-publisher', label: 'Submit your manuscript' },
    ].filter(bc => {
      if (!canBePatched && bc.identifier === 'download') {
        return false;
      }
      return true;
    });

    //
    const hiddenMetrics = [];
    const visibleMetrics = metrics.filter(m => !hiddenMetrics.includes(m.name));



    return html`
      <div id="container">
        <header-view
          .page=${page}
          .breadcrumbs=${[]}
          .citationsSeeFullDoc=${citationsSeeFullDoc}
          .thresholdValue=${citationsThreshold}
          ?loading=${isLoading}
          ?can-download=${canDownload}
          @set-citations-threshold=${e => {
        this.dispatch({
          type: 'set_citations_threshold',
          payload: { threshold: e.detail.value },
        })
      }}
          @save-request=${e => this._saveState()}
          @guide-request=${e => this.ui.showGuide()}
          @download-request=${e => this._onDownloadRequest(e)}
          @see-full-doc=${e => {
        this.dispatch({
          type: 'see_full_doc',
          payload: { seeFullDoc: e.detail.shouldSeeFullDoc },
        });
      }}
          ></header-view>
        <report-view
          class="${page === 'report' ? 'report-page' : ''}"
          show-upload-button
          .page=${page}
          .filenameOfPatchedDoc=${document.filenameOfPatchedDoc}
          .interactive=${canBePatched}
          .parts=${partsWithSuggestions}
          .originalText=${originalText}
          .metrics=${visibleMetrics}
          .suggestions=${suggestions.citations}
          .suggestionsThatCouldNotBeApplied=${suggestions.didNotApply}
          ?loading=${isLoading}
          ?uploading=${isUploadingDocument}
          ?can-download=${canDownload}
          ?downloading=${isDownloadingDocument}
          ?show-filetype-warning=${isDotDoc}
          @terms-of-service-request=${e => this.ui.showTerms()}
          @guide-request=${e => this.ui.showGuide()}
          @upload-file=${e => this._onUploadFile(e)}
          @accept-replacement=${e => this._onAcceptReplacement(e)}
          @undo-accept-replacement=${e => this._onUndoAcceptReplacement(e)}
          @download-request=${e => this._onDownloadRequest(e)}
          @continue=${e => this._onContinue(e)}
          @navbar-left-click=${e => this._onNavbarLeft(e)}
          ></report-view>
        <modal-view
          ?open=${ephemeral.modalIsOpen}
          @closed=${e => this._onModalClosed()}>
          ${ephemeral.modalHTML}
        </modal-view>
      </div>
      <paper-toast id="notification" horizontal-align="right"></paper-toast>
    `;
  }
}
customElements.define('writefull-report', WritefullReport);
