import { LitElement, html, css } from "lit-element";
import '~/elements/annotation-view/annotation-view.js';


class LongSentence extends LitElement {

  static get properties() {
    return {
      text: { type: String, reflect: false },
    };
  }

  static get styles() {
    return css`
      :host {
        display: block;

        --long-sentence-color: mediumpurple;
      }

      :host([hidden]) { display: none; }

      #sentence {
        font-weight: var(--font-weight-light);
        line-height: var(--line-height);
        border-bottom: 4px solid var(--long-sentence-color);
      }

      annotation-view {
        --background-color: var(--long-sentence-color);
        --text-color: white;
        font-size: var(--font-size-xs);
      }

    `;
  }

  render() {
    return html`
      <div id="container">
        <annotation-view>Split / shorten sentence</annotation-view>
        <span id="sentence">${this.text}</div>
      </div>
    `
  }

}
customElements.define('long-sentence', LongSentence);
