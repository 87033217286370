export default {

  // Define the landing page
  page: 'upload',

  callbackURL: null,

  token: null,

  citationsThreshold: 0.8,

  document: {
    UUID: null,
    filename: null,
    filenameOfPatchedDoc: null,
    patched: null,
    title: null,
    originalText: null,
    dataURL: null,
    parts: [/*{UUID, text, type?}*/],
    canBePatched: false,
    isDotDoc: false,
  },

  suggestions: {
    all: [],
    citations: [],
    didNotApply: [],
  },

  metrics: [{
    name: 'Grammar',
    max: 50,
    value: 0,
  }, {
    name: 'Spelling',
    max: 50,
    value: 0,
  }, {
    name: 'Vocabulary',
    max: 50,
    value: 0,
  }, {
    name: 'Punctuation',
    max: 50,
    value: 0,
  }, {
    name: 'Style',
    max: 50,
    value: 0,
  }, {
    name: 'Citations',
    max: 50,
    value: 0,
  }],

  strings: {

  },
  /*
   * Ephemeral contains runtime state such as ongoing network requests, and any
   * other state that you wouldn't serialize.
   *
   * Due to its ephemeral nature, it should be reset to its defaults when
   * restoring app state.
   */
  ephemeral: {
    uploadingDocument: false,
    downloadingDocument: false,
    suggestRequests: [],
    modalIsOpen: false,
    modalHTML: '',
    citationsSeeFullDoc: true,
    signedIn: false
  },
};
