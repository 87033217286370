const DEBUG = process.env.NODE_ENV === "development";

const wrongEmailOrPassword = `The email and password you entered don't match`;
const unexpectedError = `An unexpected error has occurred. Please contact us at help@writefull.com`;
const invalidAccountType = `The account is not Premium. Please upgrade to use this service.`
const unableToVerifyAccount = `This account has not yet been verified. Please check your inbox for a verification link after creating your account.`

/**
 * Auth Behavior
 */
export default superclass => class extends superclass {

  constructor() {
    super(...arguments);
  }

  connectedCallback() {
    super.connectedCallback(...arguments);
    window.addEventListener('login', this._loginWithFirebase.bind(this));
  }

  disconnectedCallback() {
    window.removeEventListener('login', this._loginWithFirebase);
    super.disconnectedCallback(...arguments);
  }

  async _loginWithFirebase(e) {
    if (DEBUG) {
      console.warn("_loginWithFirebase");
      console.log(`payload: ${JSON.stringify(e.detail, null, 3)}`);
    }

    const { email, password } = e.detail;

    if (!(email && password)) {
      // TODO: emit an event to notify the invalid login
      this.ui.notify(wrongEmailOrPassword);
      return;
    }


    try {
      const user = await this._signIn(email, password);

      if (user === null) {
        // TODO: emit an event to notify the invalid login
        this.ui.notify(wrongEmailOrPassword);
        return;
      }

      const { idToken } = user;

      const isEmailVerified = !!(idToken && await this._isTheEmailVerified(idToken));
      const accountTypeInfo = await this._getUserAccountType(idToken);
      const isPremium = !!(accountTypeInfo && accountTypeInfo.isPremium);
      const isPaid = !!(accountTypeInfo && accountTypeInfo.isPaid);

      const payload = {
        ...user,
        verified: isEmailVerified,
        premium: isPremium,
        paid: isPaid,
      }

      this.dispatch({
        type: 'set_user',
        payload,
      })

      if (isPremium) {

        if (DEBUG) {
          console.log(`state.get('user'): ${JSON.stringify(this.state.get("user"), null, 3)}`);
        }

        this.dispatch({
          type: 'go_to_page',
          payload: { page: 'upload' }
        }

        );
        return;
      } else {
        this.ui.notify(invalidAccountType)
        this.dispatchEvent(new CustomEvent('non-premium-login-attempt', {
          bubbles: true,
          composed: true,
          detail: {},
        }));
      }

    } catch (error) {
      if (DEBUG) {
        console.error(JSON.stringify(error, null, 3));
      }

      if (error.message == "INVALID_LOGIN") {
        this.ui.notify(wrongEmailOrPassword)
        this.dispatchEvent(new CustomEvent('invalid-email-attempt', {
          bubbles: true,
          composed: true,
          detail: {},
        }));
        return;
      }

      this.ui.notify(unexpectedError);
      this.dispatchEvent(new CustomEvent('unexpected error', {
        bubbles: true,
        composed: true,
        detail: {},
      }));
      return;
    }
  }

  async _signIn(email, password) {
    let user = null;
    const url = process.env.auth_url + "/auth";
    try {
      const response = await this.http
        .post(url)
        .set("Content-Type", "application/json")
        .send({
          email,
          password,
        })
        .ok((res) => res.status < 500);

      user = response.body;
    } catch (error) {
      const err = this._parseWritefullAuthAPIError(error);
      throw new Error(err.message || err);
    }

    return user;
  }

  async _isTheEmailVerified(idToken) {
    const url = process.env.auth_url + "/user";
    const params = "?idToken=" + idToken;

    let isVerified = false;

    try {
      const response = await this.http.get(`${url}${params}`);

      isVerified = response.body && response.body.email_verified;
    } catch (error) {
      const err = this._parseWritefullAuthAPIError(error);
      throw new Error(err.message || err);
    }

    return isVerified;
  }

  async _getUserAccountType(idToken) {
    let accountTypeInfo;
    if (idToken && idToken != "") {
      try {
        const response = await this.http
          .post(process.env.writefull_is_premium_url)
          .set({
            "firebase-token": idToken,
          })
          .send({});

        accountTypeInfo = response.body;
      } catch (error) {
        const err = this._parseWritefullAuthAPIError(error);
        throw new Error(err.message || err);
      }
    }

    return accountTypeInfo;
  }

  _parseWritefullAuthAPIError(error) {
    return error && error.response
      ? {
        status: error.response.statusCode,
        message:
          error.response.body &&
            error.response.body.error &&
            error.response.body.error.code
            ? error.response.body.error.code
            : error.response.text,
      }
      : error;
  }
};
