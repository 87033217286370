import mockPaper from '~/data/short-mock-paper.js';

const fileSizeLimit = 3.5; // MB

function _notifyFileTooLarge(notify) {
  notify(`The file you are trying to upload is too large. The size limit is ${Number.parseInt(fileSizeLimit)} MB.`);
}

/**
 * FetchPaperBehavior
 */
export default superclass => class extends superclass {

  // TODO
  //
  // This stub must be completed once we have requirements outlining how
  // papers can be fetched.
  async _fetchPaper() {
    // First check if we were passed the paper UUID in the URL hash.
    const UUID = _docUUIDFromURL();

    return {
      UUID,
      text: normalizeText(mockPaper) || null,
    }
  }

  async _onUploadFile(e) {
    const { dataURL, filename, size } = e.detail;
    if ((size / 1000000) > fileSizeLimit) {
      try {
        mixpanel.track('file-too-large', { size: size / 1000000 })
      } catch (error) { }
      return _notifyFileTooLarge(this.ui.notify.bind(this));
    }
    let text;
    let error;
    try {
      this.dispatch({
        type: 'upload_file_start',
        payload: { filename },
      });

      const extractTextEndpoint = process.env.doc_url + '/text';

      const res = (
        await this.http.post(extractTextEndpoint)
          .set({ 'beta-token': this.state.get('token') }) // FIXME
          .send({
            UUID: 'test-999', // FIXME
            dataURL,
          })
      );

      const { fileType } = res.body;
      try {
        mixpanel.register({ filetype: fileType });
      } catch (error) { }
      this.dispatch({
        type: 'update_document',
        payload: {
          canBePatched: fileType === 'docx',
          isDotDoc: fileType === 'doc',
        },
      });

      text = normalizeText(res.body.text);

    } catch (fetchError) {
      error = fetchError;
      this._handleErrorUploadingFile(fetchError);
    } finally {
      this.dispatch({
        type: 'upload_file_end',
        payload: {
          text: text,
          dataURL,
          error,
        },
      });
    }

    if (text) {
      await this._dispatchDocumentUpdateFromText(text);
      setTimeout(() => this.suggestCitations());
    }
  }

  _handleErrorUploadingFile(error) {
    console.error(error);
    try {
      mixpanel.track('error-uploading', { error });
    } catch (error) { }
    (async () => {
      try {
        const extension = this.state.getIn(['document', 'filename'])
          .split('.').slice(1).join('.');
        await this.http.post(process.env.error_url).send({
          message: `Error uploading paper with extension "${extension}"`,
          error,
        });
      } catch (error) { }
    })();
    const showNotification = text => {
      this.ui.notify(text);
    };
    switch (error.status) {
      case 413: // (Payload Too Large)
        return this._notifyFileTooLarge(showNotification);
      case 415: // (Unsupported Media Type)
        return showNotification('We were unable to parse the file you uploaded. We currently support .docx');
      case 403:
        return this.ui.notify('This IP has exceeded the maximum number of free uploads per day. Request an increase at help@writefull.com', { duration: 8000 });
      default:
        return showNotification('We were unable to upload your file. Try again later, and if it still does not work, contact us at help@writefull.com');
    }
  }

}

function normalizeText(text) {
  // TODO: Decide how to implement this.
  return text.replace(/ {2,}/g, ' ');
}

function _docUUIDFromURL() {
  let UUID = null;
  try {
    UUID = window.location.pathname.split('/').slice(-1)[0];
  } catch (error) { }
  return UUID;
}
