import Immutable from 'immutable';
const { fromJS } = Immutable;

export default function ephemeralReducer(state, action) {
  switch (action.type) {
    case 'correct_part_start':
      return registerCorrection(state, action);
    case 'correct_part_end':
      return unregisterCorrection(state, action);
    case 'upload_file_start':
      return registerFileUpload(state, action);
    case 'upload_file_end':
      return unregisterFileUpload(state, action);
    case 'show_modal':
    case 'hide_modal':
      return updateModal(state, action);
    case 'prepare_download_doc_start':
    case 'prepare_download_doc_end':
      return updateDownloadingDocStatus(state, action);
    case 'see_full_doc':
      return updateSeeFullDoc(state, action);
    default:
      return state;
  }
}

function updateSeeFullDoc(state, action) {
  return state.setIn(
    ['ephemeral', 'citationsSeeFullDoc'],
    action.payload.seeFullDoc,
  );
}

function registerCorrection(state, action) {
  return state.mergeIn(
    ['ephemeral', 'suggestRequests'],
    fromJS([action.payload]),
  );
}

function unregisterCorrection(state, action) {
  return state.updateIn(['ephemeral', 'suggestRequests'], requests =>
    requests.filter(req => req.get('UUID') !== action.payload.UUID)
  );
}

function registerFileUpload(state, action) {
  return state.setIn(['ephemeral', 'uploadingDocument'], true);
}

function unregisterFileUpload(state, action) {
  return state.setIn(['ephemeral', 'uploadingDocument'], false);
}

function updateModal(state, action) {
  let newState = state;
  switch (action.type) {
    case 'show_modal':
      newState = newState.setIn(['ephemeral', 'modalIsOpen'], true);
      newState = newState.setIn(['ephemeral', 'modalHTML'], action.payload.html || '');
      break
    case 'hide_modal':
      newState = newState.setIn(['ephemeral', 'modalIsOpen'], false);
      newState = newState.setIn(['ephemeral', 'modalHTML'], '');
      break
  }
  return newState;
}

function updateDownloadingDocStatus(state, action) {
  return state.setIn(
    ['ephemeral', 'downloadingDocument'],
    action.type === 'prepare_download_doc_start',
  );
}
