import { LitElement, html, css } from 'lit-element';
import '@material/mwc-linear-progress';
import '@material/mwc-button';
import '@material/mwc-formfield'
import '@material/mwc-switch'
import '@material/mwc-slider'
import { sharedStyles } from "~/elements/shared-styles/shared-styles.js";
import '~/elements/breadcrumbs-view/breadcrumbs-view.js';
import logo from '~/res/WLogo.png';


class HeaderView extends LitElement {

  static get properties() {
    return {
      loading: { type: Boolean, reflect: true },
      showSaveButton: { type: Boolean, attribute: 'show-save-button' },
      canDownload: { type: Boolean, attribute: 'can-download' },
      breadcrumbs: { type: Array },
      page: { type: String },
      citationsSeeFullDoc: { type: Boolean },
      thresholdValue: { type: Number },
    };
  }

  constructor() {
    super(...arguments);
    this.loading = false;
    this.showSaveButton = false;
    this.citationsSeeFullDoc = false;
    this.thresholdValue = 0.5;
  }

  _onDownloadClick(e) {
    try {
      mixpanel.track('download');
    } catch (error) { }
    if (!this.canDownload) {
      e.preventDefault();
      e.stopPropagation();
      return false;
    }
    this.dispatchEvent(new CustomEvent('download-request'));
  }

  _onGuideClick(e) {
    try {
      mixpanel.track('guide')
    } catch (error) { }
    this.dispatchEvent(new CustomEvent('guide-request'));
  }

  _onSaveClick(e) {
    this.dispatchEvent(new CustomEvent('save-request'));
  }

  static get styles() {
    return [
      sharedStyles,
      css`
        :host {
          --side-padding: var(--padding-lg);
          display: block;
        }
        :host([hidden]) { display: none; }

        #container {
          display: flex;
          flex-flow: row nowrap;
          width: 100%;
          height: 100%;
        }

        #left,
        #right {
          flex: 1;
        }

        #left {
          display: flex;
          flex-flow: row nowrap;
          justify-content: left;
          align-items: center;
          flex: 1;
          margin-left: var(--side-padding);
        }

        #middle {
          flex: 100;
          display: flex;
          flex-flow: row nowrap;
          justify-content: center;
          align-items: center;
        }

        #right {
          display: flex;
          margin-right: var(--side-padding);
        }

        #logo-container {
          font-size: var(--font-size, 16px);
          white-space: nowrap;
          display: flex;
          align-items: center;
        }

        #logo {
          margin: 0 var(--padding-sm) 0 0;
          max-width: 100px;
        }

        #buttons-container {
          display: flex;
          width: 100%;
          height: 100%;
        }

        .button {
          display: flex;
          align-items: center;
          justify-content: center;
          padding: var(--padding, 16px);
          cursor: pointer;
        }
        .button:not(.disabled):hover {
          color: var(--link-color);
          opacity: 0.8;
        }

        .button.disabled {
          color: black;
          opacity: 0.5;
          cursor: default;
        }

        #progress {
          --mdc-theme-primary: black;
          --mdc-theme-secondary: gray;
        }

        breadcrumbs-view {
          margin-left: 32px;
        }
      `,
    ];
  }

  render() {
    return html`
      <div id="container">
        <div id="left">
          <div id="logo-container">
            <img id="logo"
                 src=${logo}
                 alt="Writefull icon">
          </div>
        </div>
        <div id="middle">
          <breadcrumbs-view .selected=${this.page} .breadcrumbs=${this.breadcrumbs}></breadcrumbs-view>
              ${this.page === 'report' ? html`
                <mwc-formfield label="Compact View" style="width: 200px;">
                  <mwc-switch ?checked=${!this.citationsSeeFullDoc} @change=${e => {
          this.dispatchEvent(new CustomEvent('see-full-doc', {
            composed: true,
            bubbles: true,
            detail: {
              shouldSeeFullDoc: !this.citationsSeeFullDoc,
            }
          }))
        }}></mwc-switch>
                </mwc-formfield>
              <div>
                <mwc-slider max="1" min="0.5" value="${this.thresholdValue}"
                  @input=${e => {
          this.dispatchEvent(new CustomEvent('set-citations-threshold', {
            bubbles: true,
            composed: true,
            detail: { value: e.detail.value },
          }))
        }}></mwc-slider>
              </div>
      <div style="display: inline-block; width: 30px; font-family: var(--font-family-secondary); margin-left: 16px; font-size: 14px; color: rgba(0, 0, 0, 0.87);">${this.thresholdValue.toFixed(2)}</div>
              ` : ''}
        </div>
        <div id="right">
          <div id="buttons-container">
            <div class="centered">
              <mwc-button outlined
                @click=${e => this._onGuideClick(e)}>Guide</mwc-button>
            </div>
            ${this.showSaveButton ? html`
              <span class="button" @click=${e => this._onSaveClick(e)}>Save</span>
            ` : ''}
            <span style="display: none;" class="button ${!this.canDownload ? 'disabled' : ''}"
              @click=${e => this._onDownloadClick(e)}>Download</span>
          </div>
        </div>
      </div>
      <div id="loader" ?hidden=${!this.loading}>
        <mwc-linear-progress id="progress"></mwc-linear-progress>
      </div>
    `
  }
}
customElements.define('header-view', HeaderView);
